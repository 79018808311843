import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Loading } from 'app/src/elements/DataState';
import ExternalLink from 'app/src/elements/ExternalLink';

import { Button } from 'app/src/elements/buttons';
import { Fields, defaultValues, EMAIL_PATTERN } from 'app/src/elements/forms';
import { Notice } from 'app/src/elements/Notice';
import { Title, Text } from 'app/src/styles';

import formatAuthErrors from './formatAuthErrors';
import { ActionLink, ActionLabel } from './ActionLink';
import EmailHelp from './EmailHelp';
import FormContainer from './FormContainer';
import {
  USER_STATUS,
  REGISTER_USER,
  LOGIN,
  SEND_PASSWORD_RESET,
  REGISTRATION_CODE_DEALER,
} from './queries';
import OrderTags from './OrderTags';

const RegistrationCode = styled(Text)`
  margin-bottom: 20px;
`;

const REGISTER_FIELDS = {
  email: {
    rules: {
      required: true,
      pattern: { value: EMAIL_PATTERN, message: 'Enter a valid email' },
    },
    inputProps: { placeholder: 'your.email@example.com' }
  },
  firstName: {
    rules: { required: true },
    inputProps: { autoFocus: true }
  },
  lastName: { rules: { required: true } },
};

const TOTP_FIELDS = {
  totp: {
    label: 'Two-Factor Code',
    hint: 'Code from your authenticator app',
    rules: { required: true },
  },
};

const TOTP_EMAIL_FIELDS = {
  totp: {
    label: 'Two-Factor Code',
    hint: 'You should receive an email with this code momentarily',
    rules: { required: true },
  },
};

const FIELDS = {
  ...REGISTER_FIELDS,
  ...TOTP_FIELDS,
  password: {
    rules: { required: true },
    inputProps: {
      secureTextEntry: true,
      placeholder: 'Password',
      autoFocus: true
    }
  },
};

const LOGIN_FIELDS = {
  email: FIELDS.email,
  password: FIELDS.password,
};

const returnToHome = () => window.location.href = '/';

const RegisterLogin = ({ registrationCode }) => {
  const [formStatus, setFormStatus] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { updateCredentials } = useContext(ClientContext);

  const { control, handleSubmit, watch, reset, formState } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  const watchEmail = watch('email');

  const { errors } = formState;

  const [loadUserStatus, { data }] = useLazyQuery(USER_STATUS);

  const [loadRegistrationCodeDealer, { data: registrationCodeData }] =
    useLazyQuery(REGISTRATION_CODE_DEALER);

  useEffect(() => {
    if (registrationCode) {
      loadRegistrationCodeDealer({ variables: { code: registrationCode } });
    }
  }, [registrationCode]);

  const [login] = useMutation(LOGIN, {
    onCompleted: data => {
      updateCredentials(data.userLogin.credentials);
      returnToHome();
    },
    onError: error => {
      setErrorMessage(formatAuthErrors(error.graphQLErrors));
      setFormStatus(null);
    }
  });

  const [registerUser] = useMutation(REGISTER_USER, {
    onCompleted: data => {
      if (data.registerUser.user) {
        setFormStatus('success');
      }
    }
  });

  const [sendPasswordReset] = useMutation(SEND_PASSWORD_RESET, {
    onCompleted: () => setFormStatus('reset-sent')
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    setErrorMessage(null);
    registerUser({ variables: { ...input, registrationCode } });
  };

  const onCheckStatus = input => {
    loadUserStatus({ variables: { email: input.email }});
  };

  const onLogin = input => {
    setFormStatus('submitting');
    setErrorMessage(null);
    login({
      variables: {
        email: input.email,
        password: input.password,
        totp: input.totp,
        registrationCode,
      }
    });
  };

  const onPasswordReset = input => {
    sendPasswordReset({
      variables: {
        email: watchEmail,
        redirectUrl: window.location.origin,
      }
    });
  };

  const RegistrationCodeDealer = () => {
    if (!registrationCodeData?.registrationCodeDealer) { return null; }

    return (
      <View style={{ marginBottom: 20 }}>
        <Text>Registration code is valid for:</Text>
        <Text style={{ fontWeight: 600 }}>
          {registrationCodeData?.registrationCodeDealer.name}
        </Text>
        <Text>Login or Register to connect your account.</Text>
      </View>
    );
  };

  let content = (
    <FormContainer onEnter={handleSubmit(onCheckStatus)}>
      <Title>Login or Register</Title>
      <RegistrationCodeDealer />
      <Fields
        fields={{ email: REGISTER_FIELDS.email }}
        control={control}
        errors={errors}
      />
      <Button label="Next" onPress={handleSubmit(onCheckStatus)} wide />

      <OrderTags />
    </FormContainer>
  );

  if ('success' === formStatus) {
    content = (
      <Notice type="success">
        Check your email for next steps.
      </Notice>
    );
  } else if ('reset-sent' === formStatus) {
    content = (
      <Notice type="success">
        Check your email for a link to reset your password.
      </Notice>
    );
  } else if ('submitting' === formStatus) {
    content = <Loading />;
  } else if ('available' === data?.userStatus) {
    content = (
      <FormContainer onEnter={handleSubmit(onSubmit)}>
        <Title>Register</Title>
        <RegistrationCodeDealer />
        <Fields fields={REGISTER_FIELDS} control={control} errors={errors} />
        <Button label="Register" onPress={handleSubmit(onSubmit)} wide />

        <ActionLink onPress={returnToHome}>
          <ActionLabel>Back to Login</ActionLabel>
        </ActionLink>
      </FormContainer>
    )
  } else if ('invited' === data?.userStatus) {
    content = (
      <>
        <Notice type="warning">
          Your email has not been confirmed.
        </Notice>
        <Button label="Resend Email" onPress={onPasswordReset} wide />

        <EmailHelp email={watchEmail} />
      </>
    );
  } else if (['confirmed', 'totp', 'totp_email'].includes(data?.userStatus)) {
    content = (
      <>
        <FormContainer onEnter={handleSubmit(onLogin)}>
          <Title>Login</Title>
          <RegistrationCodeDealer />
          {errorMessage && <Notice type="error">{errorMessage}</Notice>}
          <Fields fields={LOGIN_FIELDS} control={control} errors={errors} />

          {'totp' === data.userStatus && (
            <Fields fields={TOTP_FIELDS} control={control} errors={errors} />
          )}

          {'totp_email' === data.userStatus && (
            <Fields
              fields={TOTP_EMAIL_FIELDS}
              control={control}
              errors={errors}
            />
          )}

          <Button label="Login" onPress={handleSubmit(onLogin)} wide />
        </FormContainer>

        <ActionLink onPress={onPasswordReset}>
          <ActionLabel>Send Password Reset Email</ActionLabel>
        </ActionLink>

        <EmailHelp email={watchEmail} />
      </>
    );
  } else if (registrationCode && !registrationCodeData) {
    content = (
      <RegistrationCode>
        Checking Registration Code...
      </RegistrationCode>
    );
  } else if (
    registrationCodeData && registrationCodeData.registrationCodeDealer.error
  ) {
    content = (
      <>
        <RegistrationCode>
          {registrationCodeData.registrationCodeDealer.message}
        </RegistrationCode>

        <RegistrationCode>
          You can click below to login or register, then click "Add Dealer" in
          the menu to enter your dealership details.
          <ExternalLink url="/how-to/account" target="_blank">
            <Text>{' '}Click here for a video walkthrough.</Text>
          </ExternalLink>
        </RegistrationCode>

        <RegistrationCode>
          You can also contact us at service@oklahomatemptag.com
        </RegistrationCode>

        <Button
          label="Return to Login or Register"
          onPress={returnToHome}
          wide
        />
      </>
    );
  }

  return (
    <View>
      {content}
    </View>
  );
};

export default RegisterLogin;
