import React, { useContext, useState } from 'react';
import { View, Text, Pressable } from 'react-native';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

import ClientContext from 'app/src/contexts/ClientContext';
import { Link } from 'app/src/utils/routing';
import ExternalLink from 'app/src/elements/ExternalLink';

import {
  DEALER_ITEMS,
  EMPLOYEE_ITEMS,
  ADMIN_ITEMS,
  ACCOUNT_ITEMS,
} from './index';

const Container = styled(Pressable)`
  background-color: black;
  padding: 10px;
  justify-content: space-between;
  min-height: 40px;
  z-index: 10000;
  gap: 20px;
`;

const Items = styled(View)`
  background-color: black;
  gap: 10px;
`;

const Label = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: white;
`;

const SectionBreak = styled(View)`
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: lightgray;
`;

const MobileMenu = () => {
  const { currentUser, activeDealers, logout } = useContext(ClientContext);
  const [showMenu, setShowMenu] = useState(false);

  let items = [];
  if ('dealer' === currentUser?.role) {
    if (0 === activeDealers.length) {
      items = [...ACCOUNT_ITEMS];
    } else {
      items = [...DEALER_ITEMS, { sectionBreak: true }, ...ACCOUNT_ITEMS];
    }
  } else if ('admin' === currentUser?.role) {
    items = [
      ...EMPLOYEE_ITEMS,
      ...ADMIN_ITEMS,
      { sectionBreak: true },
      ...ACCOUNT_ITEMS
    ];
  } else if (currentUser) {
    items = [...EMPLOYEE_ITEMS, { sectionBreak: true }, ...ACCOUNT_ITEMS];
  }

  return (
    <Container onPress={() => setShowMenu(!showMenu)}>
      <FontAwesomeIcon
        icon={showMenu ? faXmark : faBars}
        color="white"
        size={20}
      />

      {showMenu && (
        <Items>
          <SectionBreak />
          {items.map(({ label, path, url, action, sectionBreak }, index) => {
            if (sectionBreak) {
              return (
                <SectionBreak key={index} />
              );
            } else if ('logout' === action) {
              return (
                <Pressable key={label} onPress={logout}>
                  <Label>Logout</Label>
                </Pressable>
              );
            } else if (url) {
              return (
                <ExternalLink key={label} url={url}>
                  <Label>{label}</Label>
                </ExternalLink>
              );
            } else {
              return (
                <Link key={label} to={path}>
                  <Label>{label}</Label>
                </Link>
              );
            }
          })}
        </Items>
      )}
    </Container>
  );
};

export default MobileMenu;
