import React, { useContext, useEffect, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/native';

import NewDealer from 'app/src/components/Dealers/new';
import Notes from 'app/src/components/Notes/List';
import ClientContext from 'app/src/contexts/ClientContext';
import { Fields, defaultValues, prepareInput } from 'app/src/elements/forms';
import Input from 'app/src/elements/inputs';
import Modal from 'app/src/elements/Modal';
import { Text, Title, Subtitle } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { useNavigate } from 'app/src/utils/routing';

import CommissionDetails from './CommissionDetails';
import DetailFields from './DetailFields';
import { CREATE_ORDER } from './queries';
import setupOrderFields from './setupOrderFields';

import DealerOrders from 'app/src/components/Dealers/Orders';
import { DEALER } from 'app/src/components/Dealers/queries';
import { SEARCH } from 'app/src/components/Search/queries';

const TEMPLATE_OPTIONS = [
  { value: '', label: 'Select' },
  { value: 'blank', label: 'Blank Tags' },
  { value: 'full', label: 'Printed Tags' }
];

const DEALER_FIELDS = {
  dealerId: {
    label: 'Choose Dealer',
    hint: 'Start typing name or license number',
    clientSelect: 'activeDealers',
    oneOption: 'no-choice',
    rules: { required: true },
  },
};

export const ORDER_FIELDS = {
  productId: {
    label: 'Product',
    type: 'product',
    rules: { required: true },
  },
  shopifyOrderNumber: {
    label: 'Shopify Order Number',
    hint: "Optional, don't include # sign",
    type: 'string',
  },
  quantity: {
    label: 'Number of Tags',
    type: 'multiplier',
    rules: { required: true },
    inputProps: {
      multiplierLabel: 'or Packages of 1',
      multiplier: 1,
    },
  },
  newLicenseId: {},
  usedLicenseId: {},
};

export const FIELDS = { ...DEALER_FIELDS, ...ORDER_FIELDS };

export const Additional = styled(View)`
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding: 20px;
  gap: 30px;
`;

const NewOrder = () => {
  const {
    currentUser, activeDealers, activeProducts
  } = useContext(ClientContext);

  if (!currentUser?.isEmployee) {
    return null;
  }

  const navigate = useNavigate();
  const [dealer, setDealer] = useState();
  const [showNewDealerModal, setShowNewDealerModal] = useState(false);

  const {
    control, handleSubmit, setValue, watch, formState: { errors }
  } = useForm({
    defaultValues: { quantity: '', template: 'full' }
  });

  const values = watch();

  const [getDealer] = useLazyQuery(DEALER, {
    fetchPolicy: 'no-cache',
    onCompleted: data => setDealer(data.dealer),
  });

  const [createOrder] = useMutation(CREATE_ORDER, {
    onCompleted: data => navigate(`/orders/${data.createOrder.order.id}/print`)
  });

  const onSubmit = input => {
    createOrder({ variables: prepareInput(input, FIELDS) });
  };

  useEffect(() => {
    if (values.dealerId && dealer?.id !== values.dealerId) {
      getDealer({ variables: { id: values.dealerId }});
    }
  }, [values.dealerId]);

  if (0 === activeDealers?.length && !currentUser?.isEmployee) {
    return (
      <Title>You must setup a dealer before creating an order</Title>
    );
  }

  const dealerFields = { ...DEALER_FIELDS };
  const orderFields = setupOrderFields({ dealer, values, activeProducts });

  if (currentUser?.isEmployee) {
    dealerFields.dealerId = { ...dealerFields.dealerId };

    dealerFields.dealerId.autocomplete = {
      query: SEARCH,
      queryName: 'search',
      variables: { type: 'Dealers' },
      selectedLabel: values.dealerName || '',
      setSelected: selected => {
        setValue('dealerId', selected?.id);
        setValue('dealerName', selected?.name);
        setValue('productId', null);
        setValue('newLicenseId', '');
        setValue('usedLicenseId', '');
      }
    };
  }

  const dealerCreated = createdDealer => {
    setValue('dealerId', createdDealer.id);
    setValue('dealerName', createdDealer.name);
    setShowNewDealerModal(false);
  };

  return (
    <View>
      <Title>Add Order</Title>

      <View style={{ flexDirection: 'row', gap: 24 }}>
        <View style={{ flex: 1, zIndex: 500 }}>
          <Fields
            fields={dealerFields}
            control={control}
            errors={errors}
            setValue={setValue}
          />

          {dealer ? (
            <>
              <DetailFields
                dealer={dealer}
                orderFields={orderFields}
                handleSubmit={handleSubmit(onSubmit)}
                control={control}
                errors={errors}
                setValue={setValue}
                getDealer={getDealer}
              />

              <Subtitle bordered>Dealer Notes</Subtitle>
              <Notes
                notes={dealer.notes}
                dealer={dealer}
                refetch={() => {
                  getDealer({ variables: { id: values.dealerId } });
                }}
              />
            </>
          ) : (
            <Pressable onPress={() => setShowNewDealerModal(true)}>
              <Text style={{ fontWeight: 600, color: colors.header }}>
                + Setup New Dealer
              </Text>
            </Pressable>
          )}
        </View>

        {dealer ? (
          <Additional>
            <CommissionDetails dealer={dealer} />
            <DealerOrders title="Past Orders" orders={dealer.orders} />
          </Additional>
        ) : (
          <View style={{ flex: 1 }}>
          </View>
        )}
      </View>

      {showNewDealerModal && (
        <Modal title="Add Dealer" setShow={setShowNewDealerModal}>
          <NewDealer onCreated={dealerCreated} />
        </Modal>
      )}
    </View>
  );
};

export default NewOrder;
