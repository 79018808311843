import React from 'react';
import { Routes, Route } from 'app/src/utils/routing';

import Docs from './index';
import All from './All';
import Account from './Account';
import Users from './Users';
import Tags from './Tags';
import PreRegisterSale from './Tags/PreRegisterSale';

const DocRoutes = ({ prefix }) => {
  return (
    <Routes>
      <Route path={prefix} element={<Docs />}>
        <Route index element={<All />} />
        <Route path="account" element={<Account />} />
        <Route path="users" element={<Users />} />
        <Route path="pre-registration" element={<Tags />} />
        <Route path="pre-register" element={<PreRegisterSale />} />
      </Route>
    </Routes>
  );
};

export default DocRoutes;
