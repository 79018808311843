import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useLazyQuery } from '@apollo/client';
import { Controller } from 'react-hook-form';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import {
  FieldLabel,
  StyledTextInput,
  StyledPicker,
} from 'app/src/elements/inputs';
import { Picker } from 'app/src/elements/Picker';
import { Text, Warning } from 'app/src/styles';

import { CHECK_LICENSE_REGISTRATION } from './queries';

export const LICENSE_TYPES = [
  { value: '', label: 'Select type' },
  { value: 'UD', label: 'UD - Used Motor Vehicle Dealer' },
  { value: 'D', label: 'D - New Motor Vehicle Dealer' },
  { value: 'DM', label: 'D - New Motorcycle Dealer' },
  { value: 'TV', label: 'TV' },
  { value: 'CT', label: 'CT' },
  { value: 'C', label: 'Other' },
];

export const LICENSE_RULES =
  { required: 'License type and number are required' };

export const LICENSE_HINT = 'Choose a license type then enter the number. ' +
  'If your license type is not listed, choose "Other" and enter the ' +
  'full license string'

const Inputs = styled.View`
  flex-direction: row;
  margin-bottom: 10px;
`;

const LicenseInput = props => {
  const {
    license, index, control, rules, hint, errors, checkRegistered
  } = props;

  const { currentUser } = useContext(ClientContext);
  const [admins, setAdmins] = useState([]);

  const [checkLicenseRegistration] = useLazyQuery(CHECK_LICENSE_REGISTRATION, {
    onCompleted: data => setAdmins(data.checkLicenseRegistration)
  });

  if (errors.licensesAttributes?.[index]?.licenseType) {
    errors.licensesAttributes[index].licenseNumber =
      errors.licensesAttributes[index].licenseType;
  }

  useEffect(() => {
    if (
      checkRegistered &&
      license.licenseType && license.licenseNumber.length >= 4
    ) {
      checkLicenseRegistration({ variables: license });
    }
  }, [license.licenseType, license.licenseNumber]);

  return (
    <View>
      <FieldLabel
        name={`licensesAttributes.${index}.licenseNumber`}
        label={`License #${index + 1}`}
        hint={hint}
        errors={errors}
      />
      <Inputs>
        <Controller
          control={control}
          name={`licensesAttributes.${index}.licenseType`}
          rules={rules}
          render={({ field }) => (
            <StyledPicker
              selectedValue={field.value}
              onValueChange={field.onChange}
            >
              {LICENSE_TYPES.map(item => (
                <Picker.Item key={item.value} {...item} />
              ))}
            </StyledPicker>
          )}
        />
        <Controller
          control={control}
          name={`licensesAttributes.${index}.licenseNumber`}
          rules={{
            ...rules,
            maxLength: {
              value: 9,
              message: 'License number too long. Do not include year suffix',
            },
            validate: value => (
              !value.toUpperCase().startsWith('YHZ') ||
                'Please provide a license number, not a tag number'
            ),
          }}
          render={({ field }) => (
            <StyledTextInput {...field} onChangeText={field.onChange} />
          )}
        />
      </Inputs>
      {admins.length > 0 && (
        <Warning>
          <Text style={{ marginBottom: 12 }}>
            A dealership with this license is already registered. Please contact
            an account admin for access. Below is redacted information that may
            help you identify an account admin at your dealership if you're not
            sure who to contact.
          </Text>

          {admins.map(contact => (<Text key={contact}>{contact}</Text>))}
        </Warning>
      )}
    </View>
  );
};

export default LicenseInput;
