import React, { useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';

import { Title, Text } from 'app/src/styles';

import { UPDATE_OIADA_MEMBERS } from './queries';

const Members = () => {
  const [formStatus, setFormStatus] = useState();

  const [updateOiadaMembers] = useMutation(UPDATE_OIADA_MEMBERS, {
    onCompleted: () => setFormStatus(null)
  });

  const uploadFile = file => {
    setFormStatus('processing...');
    updateOiadaMembers({ variables: { file } })
  };

  return (
    <View>
      <Title>Upload OIADA Members Spreadsheet</Title>
      {formStatus ? (
        <Text>{formStatus}</Text>
      ) : (
        <input type="file" onChange={e => uploadFile(e.target.files[0])} />
      )}
    </View>
  );
};

export default Members;
