import React from 'react';
import { View } from 'react-native';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { Loading } from 'app/src/elements/DataState';
import { Button } from 'app/src/elements/buttons';
import { Fields, defaultValues } from 'app/src/elements/forms';
import { Notice } from 'app/src/elements/Notice';
import { Text, Subtitle } from 'app/src/styles';

import FormContainer from './FormContainer';

const CURRENT_PASSWORD_FIELDS = {
  currentPassword: {
    label: 'Current Password',
    rules: { required: true },
    inputProps: { secureTextEntry: true },
  },
};

const FIELDS = {
  password: {
    rules: {
      required: true,
      minLength: { value: 8, message: 'Must be at least 8 characters' },
      pattern: {
        value: /\d/,
        message: 'Must include at least 1 number',
      },
    },
    inputProps: { secureTextEntry: true },
  },
  passwordConfirmation: {
    label: 'Confirm Password',
    rules: { required: true },
    inputProps: { secureTextEntry: true },
  },
};

const ValidationRow = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const ValidationLabel = styled(Text)`
  color: ${props => props.validated ? 'green' : 'gray'};
`;

const ValidationCheck = styled(View)`
  width: 24px;
`;

const Validation = ({ label, validated }) => {
  return (
    <ValidationRow>
      <ValidationCheck>
        {!!validated && (
          <FontAwesomeIcon icon={faCircleCheck} color="green" />
        )}
      </ValidationCheck>
      <ValidationLabel validated={validated}>{label}</ValidationLabel>
    </ValidationRow>
  );
};

const PasswordForm = ({ formStatus, onSubmit, currentPassword }) => {
  const fields = currentPassword ?
    { ...CURRENT_PASSWORD_FIELDS, ...FIELDS } :
    { ...FIELDS };

  const {
    control, handleSubmit, reset, watch, formState: { errors }
  } = useForm({
    defaultValues: defaultValues({ fields })
  });

  const watchPassword = watch('password');
  const watchConfirmation = watch('passwordConfirmation');

  fields.passwordConfirmation.rules = {
    required: true,
    validate: value => {
      if (watchPassword !== value) {
        return 'Password does not match confirmation';
      }
    }
  };

  const validated = {
    length: watchPassword.length >= 8,
    oneNumber: watchPassword.match(/\d/),
    confirmation: watchConfirmation && watchPassword === watchConfirmation,
  };

  const canSubmit = validated.length &&
    validated.oneNumber &&
    validated.confirmation;

  if ('submitting' === formStatus) {
    return <Loading />;
  }

  return (
    <View>
      <FormContainer onEnter={handleSubmit(onSubmit)}>
        <Fields fields={fields} control={control} errors={errors} />

        <Subtitle>Password Requirements</Subtitle>

        <Validation
          label="At least 8 characters"
          validated={validated.length}
        />

        <Validation
          label="At least one number"
          validated={validated.oneNumber}
        />

        <Validation
          label="Password and confirmation must match"
          validated={validated.confirmation}
        />

        <Button
          label="Set Password"
          onPress={handleSubmit(onSubmit)}
          wide
          disabled={Object.keys(errors).length > 0 || !canSubmit}
        />
      </FormContainer>
    </View>
  );
};

export default PasswordForm;
