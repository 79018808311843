import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const NOTE_CORE_FIELDS = gql`
  fragment NoteCoreFields on Note {
    id
    type
    name
    body
    createdAt
  }
`;

export const NOTE_FIELDS = gql`
  ${NOTE_CORE_FIELDS}
  fragment NoteFields on Note {
    ...NoteCoreFields
    dealer {
      id
      name
    }
    order {
      id
      createdAt
      filledAt
    }
    user {
      id
    }
  }
`;

export const NOTES = gql`
  ${NOTE_FIELDS}
  query Notes(
    $limit: Int!
    $offset: String!
    $options: ConnectionOptionsInput!
  ) {
    notes(
      first: $limit
      after: $offset
      options: $options
    ) {
      edges {
        cursor
        node {
          ...NoteFields
        }
      }
      totalCount
    }
  }
`;

export const NOTE = gql`
  ${NOTE_FIELDS}
  query Notes ($id: ID!) {
    note (id: $id) {
      ...NoteFields
    }
  }
`;

const NOTE_ARGUMENTS = `
  $dealerId: ID
  $orderId: ID
  $userId: ID
  $body: String!
  $emailTo: String
  $emailSubject: String
`;

const NOTE_VARIABLES = `
  dealerId: $dealerId
  orderId: $orderId
  userId: $userId
  body: $body
  emailTo: $emailTo
  emailSubject: $emailSubject
`;

export const CREATE_NOTE = gql`
  ${NOTE_CORE_FIELDS}
  mutation CreateNote (
    ${NOTE_ARGUMENTS}
  ) {
    createNote (
      ${NOTE_VARIABLES}
    ) {
      note {
        ...NoteCoreFields
      }
      ${ERRORS}
    }
  }
`;
