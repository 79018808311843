import React, { useContext, useState } from 'react';
import { View, Pressable, Switch } from 'react-native';

import RelayTable from 'app/src/elements/RelayTable';
import { Title, Setting, SettingLabel } from 'app/src/styles';
import colors from 'app/src/styles/colors';

import { SERIAL_BLOCKS } from './queries';

const columns = [
  {
    key: 'prefix',
    label: 'Prefix',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'startNumber',
    label: 'Start',
  },
  {
    key: 'quantity',
    label: 'Quantity',
  },
  {
    key: 'endNumber',
    label: 'End',
  },
  {
    key: 'nextNumber',
    label: 'Next',
  },
  {
    key: 'availableQuantity',
    label: 'Available',
  },
];

const SerialBlocks = () => {
  const [scopes, setScopes] = useState(['available']);

  return (
    <View>
      <Title>Serial Blocks</Title>

      <Setting>
        <SettingLabel>Only Show Available</SettingLabel>
        <Switch
          value={scopes.includes('available')}
          onValueChange={value => setScopes(value ? ['available'] : [])}
          activeTrackColor={colors.lightButton}
          activeThumbColor={colors.button}
        />
      </Setting>

      <RelayTable
        query={SERIAL_BLOCKS}
        queryName="serialBlocks"
        queryVariables={{ scopes }}
        columns={columns}
        pageLength={10}
      />
    </View>
  );
};

export default SerialBlocks;
