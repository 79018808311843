import React from 'react';
import { View } from 'react-native';

import { Text } from 'app/src/styles';

const Success = () => {
  return (
    <View>
      <Text>
        Thank you for subscribing. You may now pre-register temporary tags sold
        by other vendors.
      </Text>
    </View>
  );
};

export default Success;
