import React from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';

import { Button } from 'app/src/elements/buttons';
import { Subtitle, Text } from 'app/src/styles';

import { RESET_USER_LAST_ACTIVITY } from './queries';

const ExpiredModal = ({ user, onCompleted }) => {
  const [resetUserLastActivity] = useMutation(RESET_USER_LAST_ACTIVITY, {
    variables: { id: user.id },
    onCompleted,
  });

  return (
    <View>
      <Subtitle>{user.fullName}</Subtitle>
      <Text>Account access expires after 60 days of inactivity.</Text>

      <Button
        label="Click hdere to reactive account."
        onPress={resetUserLastActivity}
        wide
      />
    </View>
  );
};

export default ExpiredModal;
