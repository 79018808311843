import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Button } from 'app/src/elements/buttons';
import { Fields, defaultValues } from 'app/src/elements/forms';
import Modal from 'app/src/elements/Modal';

import { CREATE_INVOICE } from './queries';

const FIELDS = {
  endOn: {
    type: 'date',
    rules: { required: true },
  },
  reference: { rules: { required: true, }, },
  amount: { rules: { required: true, }, },
};

const FinalizeInvoice = ({ setShow, refetch }) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  const [createInvoice] = useMutation(CREATE_INVOICE, {
    onCompleted: () => {
      refetch();
      setShow(false);
    }
  });

  const onSubmit = input => {
    createInvoice({ variables: input });
  };

  return (
    <Modal title="Finalize Invoice" setShow={setShow}>
      <Fields fields={FIELDS} control={control} errors={errors} />

      <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
    </Modal>
  );
};

export default FinalizeInvoice;
