import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { Button } from 'app/src/elements/buttons';
import Modal from 'app/src/elements/Modal';
import { Text, Subtitle, Warning } from 'app/src/styles';

import {
  REQUEST_FEATURE_SUBSCRIPTION_UPDATE,
  CANCEL_FEATURE_SUBSCRIPTION,
} from './queries';

const EditFeatureSubscription = ({ featureSubscription, setShow, refetch }) => {
  const [updateRequested, setUpdateRequested] = useState(false);

  const [requestFeatureSubsciptionUpdate] = useMutation(
    REQUEST_FEATURE_SUBSCRIPTION_UPDATE,
    { onCompleted: () => setUpdateRequested(true) }
  );

  const [cancelFeatureSubscription] = useMutation(CANCEL_FEATURE_SUBSCRIPTION, {
    onCompleted: () => {
      refetch();
      setShow(false);
    }
  });

  const handleUpdate = () => {
    requestFeatureSubsciptionUpdate({
      variables: { id: featureSubscription.id }
    });
  };

  const handleCancel = () => {
    if (confirm('Really cancel your subscription?')) {
      cancelFeatureSubscription({ variables: { id: featureSubscription.id } });
    }
  };

  return (
    <Modal title="Update Subscription" setShow={setShow}>
      <Subtitle>{featureSubscription.dealer.label}</Subtitle>

      {updateRequested ? (
        <Warning>
          <Text>
            You will receive an email from Stripe with a link to update
            your payment method.
          </Text>
          <Text>
            You should receive the email within 1 hour during
            normal business hours.
          </Text>

          <Button
            label="Close this Dialog Box"
            onPress={() => setShow(false)}
            wide
          />
        </Warning>
      ) : (
        <>
          <Button
            label="Update Payment Method"
            onPress={handleUpdate}
            wide
          />

          <Button label="Cancel Subscription" onPress={handleCancel} wide />
        </>
      )}
    </Modal>
  );
};

export default EditFeatureSubscription;
