import { gql } from '@apollo/client';

export const FEATURE_SUBSCRIPTIONS = gql`
  query FeatureSubscriptions (
    $limit: Int!
    $offset: String!
    $options: ConnectionOptionsInput!
  ) {
    featureSubscriptions (
      first: $limit
      after: $offset
      options: $options
    ) {
      edges {
        node {
          id
          createdAt
          deletedAt
          status
          fullStatus
          dealer { id, label }
          user { fullName }
        }
      }
      totalCount
    }
  }
`;

export const DEALER_FEATURE_SUBSCRIPTIONS = gql`
  query NoUserLogDealerFeatureSubscriptions ($id: ID!) {
    dealer (id: $id) {
      featureSubscriptions {
        id
        createdAt
        deletedAt
      }
    }
  }
`;

export const SETUP_FEATURE_SUBSCRIPTION = gql`
  mutation SetupFeatureSubscription ($dealerId: ID!) {
    setupFeatureSubscription (dealerId: $dealerId) {
      sessionUrl
    }
  }
`;

export const REQUEST_FEATURE_SUBSCRIPTION_UPDATE = gql`
  mutation RequestFeatureSubscriptionUpdate ($id: ID!) {
    requestFeatureSubscriptionUpdate (id: $id) {
      errors { path, message }
    }
  }
`;

export const CANCEL_FEATURE_SUBSCRIPTION = gql`
  mutation cancelFeatureSubscription ($id: ID!) {
    cancelFeatureSubscription (id: $id) {
      errors { path, message }
    }
  }
`;
