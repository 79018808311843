import { gql } from '@apollo/client';

const DASHBOARD_METRIC_FIELDS = gql`
  fragment DashboardMetricFields on DashboardMetric {
    label
    days
    businessDays
    count
    minTime
    maxTime
    percent
    percentLabel
  }
`;

export const DASHBOARD = gql`
  ${DASHBOARD_METRIC_FIELDS}
  query Dashboard {
    dashboard {
      orders {
        ...DashboardMetricFields
      }
      tags {
        ...DashboardMetricFields
      }
      registrations {
        ...DashboardMetricFields
      }
      users {
        ...DashboardMetricFields
      }
    }
  }
`;
