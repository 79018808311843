import React, { useContext, useEffect, useState } from 'react';
import { View, TextInput, Pressable } from 'react-native';
import { useLazyQuery } from '@apollo/client';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Text, Warning } from 'app/src/styles';
import colors from 'app/src/styles/colors';

import { Container, Input, Results } from './index';
import Result from './Result';

const SUBSTITUTIONS = {
  '0': 'O',
  'O': '0',
};

const AutocompleteSerialNumber = props => {
  const {
    selectedLabel, setSelected, query, queryName, variables, clientSelect,
    matchResultAttribute, updateTagType,
  } = props;

  const client = useContext(ClientContext);
  const [term, setTerm] = useState(selectedLabel || '');
  const [results, setResults] = useState();

  const [getResults] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted: data => setResults(data[queryName])
  });

  useEffect(() => {
    if (term && term.length > 1) {
      const matchResult = matchResultAttribute ?
        results?.find(result => result[matchResultAttribute] === term) : null;

      if (matchResult) {
        setSelected(matchResult);
      } else {
        if (selectedLabel && selectedLabel !== term) { setSelected(null); }
        getResults({ variables: { ...variables, term }});
      }
    }
  }, [term]);

  useEffect(() => {
    if (typeof(selectedLabel) !== 'undefined' && term !== selectedLabel) {
      setTerm(selectedLabel);
    }
  }, [selectedLabel]);

  const showResults = results && term !== selectedLabel &&
    term.length > 1 && results.length > 0;

  const setTermWithSubstitution = value => {
    value = value.toUpperCase();

    if (
      matchResultAttribute && results && results.length > 0 && value.length > 0
    ) {
      let match = results?.find(result => (
        result[matchResultAttribute].startsWith(value)
      ));

      if (!match) {
        const lastChar = value.charAt(value.length - 1);
        const substitution = SUBSTITUTIONS[lastChar];

        const updatedValue = value.replace(/.$/, substitution);
        if (substitution) {
          match = results?.find(result => (
            result[matchResultAttribute].startsWith(updatedValue)
          ));
        }

        if (match) { value = updatedValue };
      }
    }

    setTerm(value);
  };

  let warning = null;

  if (term && term.length > 4 && !showResults && 0 === results?.length) {
    if (term.startsWith('YHZ')) {
      warning = (
        <Text>
          There is not a unregistered temporary tag with this tag number.
        </Text>
      );
    } else {
      warning = (
        <View style={{ flexDirection: 'row' }}>
          <Text>
            To pre-register a temporary tag not purchased from ADR,{' '}
          </Text>
          <Pressable onPress={() => updateTagType('other')}>
            <Text>click here</Text>
          </Pressable>
        </View>
      );
    }
  }

  return (
    <>
      <Container>
        <Input
          placeholder="Search"
          placeholderTextColor="lightgray"
          value={term}
          onChange={e => setTermWithSubstitution(e.target.value)}
        />
        {term.length > 0 && (
          <Pressable onPress={() => { setTerm(''); setSelected(null); }}>
            <Text>X</Text>
          </Pressable>
        )}
        {showResults && (
          <Results>
            {results.map(result => (
              <Result
                key={`${result.__typename}-${result.id || result.value}`}
                result={result}
                setSelected={setSelected}
              />
            ))}
          </Results>
        )}
      </Container>
      {warning && (
        <Warning>{warning}</Warning>
      )}
    </>
  );
};

export default AutocompleteSerialNumber;
