import React from 'react';
import { View } from 'react-native';

import Header from '@preflighttech/preflight-tables/dist/components/Header';
import Row from '@preflighttech/preflight-tables/dist/components/Row';

import tableStyles from 'app/src/styles/table';

const minimalTable = props => {
  const {
    orderedColumns,
    dimensions,
    movableColumns,
    moveColumn,
    order,
    updateOrder,
    styles,
    entries,
  } = props;

  const headerStyles = {
    container: {
      ...tableStyles.header.container,
      minHeight: 28,
      textAlign: 'center',
      paddingHorizontal: 0,
      paddingVertical: 0,
      borderRightWidth: 2,
      borderRightColor: 'white',
      borderRightStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: 'white',
      borderBottomStyle: 'solid',
    },
    label: {
      ...tableStyles.header.label,
    },
  }

  const dataStyles = { ...tableStyles.data };

  dataStyles.container = {
    paddingHorizontal: 0,
    marginHorizontal: 0,
  };

  dataStyles.cell = {
    ...dataStyles.cell,
    paddingHorizontal: 4,
    marginHorizontal: 0,
    borderLeftWidth: 0,
    borderRightWidth: 2,
    borderRightColor: 'white',
    borderRightStyle: 'solid',
  };

  return (
    <View>
      <Header
        columns={orderedColumns}
        dimensions={dimensions}
        movableColumns={movableColumns}
        moveColumn={moveColumn}
        order={order}
        updateOrder={updateOrder}
        styles={headerStyles}
      />

      {
        entries.map((entry, index) => {
          return (
            <Row
              key={entry.id}
              entry={entry}
              columns={orderedColumns}
              index={index}
              dimensions={dimensions}
              styles={dataStyles}
            />
          );
        })
      }
    </View>
  );
}

export default minimalTable;
