import React from 'react';
import { View } from 'react-native';

import Invoices from 'app/src/components/Invoices';
import SerialBlocks from 'app/src/components/SerialBlocks';

import Members from './Members';

const Financial = () => {
  return (
    <View style={{ gap: 24 }}>
      <Invoices />
      <Members />
      <SerialBlocks />
    </View>
  );
};

export default Financial;
