import React, { useEffect, useRef } from 'react';
import { View } from 'react-native';
import { useQuery } from '@apollo/client';

import { useParams } from 'app/src/utils/routing';
import DataState from 'app/src/elements/DataState';
import { Text } from 'app/src/styles';

import { NOTE } from './queries';

const Note = () => {
  const { id } = useParams();
  const iframeRef = useRef();

  const { data, ...dataState } = useQuery(NOTE, { variables: { id } });

  useEffect(() => {
    if (data?.note.body) {
      // Replace is to do deal with cups page not wanting to display in frame.
      iframeRef.current.srcdoc =
        data?.note.body.replace('if (self == top) {', 'if (true) {');
    }
  }, [data]);

  return (
    <View>
      <DataState data={data} {...dataState}>
      </DataState>

      <iframe ref={iframeRef} width="100%" height="600px"></iframe>
    </View>
  );
};

export default Note;
