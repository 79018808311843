import React, { useState } from 'react';
import { View } from 'react-native';
import { useQuery } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';
import styled from 'styled-components/native';

import { Loading } from 'app/src/elements/DataState';
import { Picker } from 'app/src/elements/Picker';
import { StyledPicker } from 'app/src/elements/inputs';
import { Title, Subtitle, Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { ActionText } from 'app/src/styles/table';
import { dateShort, delimited } from 'app/src/utils/formatting';
import { Link } from 'app/src/utils/routing';

import { UPCOMING_REORDERS } from './queries';

const PastDataContainer = styled(View)`
  flex-direction: row;
  gap: 80px;
  margin-bottom: 30px;
`;

const Column = styled(View)`
`;

const PastDataHighlight = styled(Text)`
  fontSize: 40;
  fontWeight: 600;
  color: ${colors.header};
`;

const Option = styled(View)`
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
`;

const PAST_DATA_DAYS_AGO_OPTIONS = [...Array(365).keys()];
const PAST_DATA_IGNORE_OPTIONS = [0, 5, 10, 15, 20, 25, 30];
const LOW_UNREGISTERED_OPTIONS = [50, 55, 60, 65, 70, 75, 80, 85, 90];

const columns = [
  {
    key: 'dealer',
    label: 'Dealer',
    value: ({ value }) => value.name,
    content: ({ entry, value }) => (
      <Link to={`/dealers/${entry.dealer.id}`}>
        <ActionText>{value}</ActionText>
      </Link>
    )
  },
  {
    key: 'filledAt',
    label: 'Filled',
    content: dateShort,
  },
  {
    key: 'registeredCount',
    label: 'Registered Tags',
  },
  {
    key: 'quantity',
    label: 'Total Tags',
  },
  {
    key: 'remaining',
    label: 'Remaining Tags',
    value: ({ entry }) => entry.quantity - entry.registeredCount,
  },
  {
    key: 'percentRegistered',
    label: 'Registered %',
    sort: 'desc',
    value: ({ entry }) => (entry.registeredCount / entry.quantity * 100),
    content: ({ value }) => value.toFixed(2) + '%',
  },
  {
    key: 'lastRegisteredAt',
    label: 'Last Registered',
    content: dateShort,
  },
  {
    key: 'actions',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <Link to={`/orders/${entry.id}`}>
        <ActionText>View</ActionText>
      </Link>
    )
  }
];

const OrderCount = ({ label, count, total }) => {
  return (
    <View
      style={{ marginTop: 10, flexDirection: 'row', alignItems: 'baseline' }}
    >
      <Text style={{ width: 220 }}>{label}:</Text>

      <Text style={{ width: 40, fontWeight: 600, textAlign: 'right' }}>
        {delimited(count)}
      </Text>

      <Text style={{ fontWeight: 600, width: 80, textAlign: 'right' }}>
        {(count / total * 100).toFixed(2)}%
      </Text>
    </View>
  );
};

const UpcomingReorders = () => {
  const [lowUnregisteredThreshold, setLowUnregisteredThreshold] = useState(70);
  const [pastDataDaysAgo, setPastDataDaysAgo] = useState(14);
  const [pastDataIgnoreThreshold, setPastDataIgnoreThreshold] = useState(20);

  const { data } = useQuery(UPCOMING_REORDERS, {
    variables: {
      lowUnregisteredThreshold: parseFloat(lowUnregisteredThreshold),
      pastDataDaysAgo: parseInt(pastDataDaysAgo),
      pastDataIgnoreThreshold: parseFloat(pastDataIgnoreThreshold),
    }
  });

  if (!data) { return <Loading />; }

  const { lowUnregistered, pastData } = data.upcomingReorders;

  const lowUnregisteredTags = lowUnregistered
    .map(order => order.quantity)
    .reduce((sum, quantity) => sum + quantity, 0)

  const totalOrders =
    pastData.firstOrderCount +
    pastData.noRegistrationsCount +
    pastData.belowThresholdCount +
    pastData.aboveThresholdCount;

  return (
    <View>
      <Title>
        For Orders in Past {pastDataDaysAgo} Days,{' '}
        Tags Registered on Prior Order
      </Title>

      <PastDataContainer>
        <Column>
          <Subtitle>% Registered at Reorder</Subtitle>
          <PastDataHighlight>
            {pastData.averageRegistrationsPercent.toFixed(2)}%
          </PastDataHighlight>
          <Text>For orders with at least</Text>
          <Text>{pastDataIgnoreThreshold}% of tags pre-registered</Text>
        </Column>

        <Column>
          <OrderCount
            label="None, First Time Order"
            count={pastData.firstOrderCount}
            total={totalOrders}
          />

          <OrderCount
            label="No Tags Registered"
            count={pastData.noRegistrationsCount}
            total={totalOrders}
          />

          <OrderCount
            label="Below Threshold Registered"
            count={pastData.belowThresholdCount}
            total={totalOrders}
          />

          <OrderCount
            label="Above Threshold Registered"
            count={pastData.aboveThresholdCount}
            total={totalOrders}
          />
        </Column>

        <Column>
          <Option>
            <Text>Show for orders in past</Text>
            <StyledPicker
              selectedValue={pastDataDaysAgo}
              onValueChange={setPastDataDaysAgo}
            >
              {PAST_DATA_DAYS_AGO_OPTIONS.map(option => (
                <Picker.Item key={option} value={option} label={option} />
              ))}
            </StyledPicker>
            <Text>days</Text>
          </Option>

          <Option style={{ marginTop: 20, marginBottom: 6 }}>
            <Text>Change ignore threshold:</Text>
            <StyledPicker
              selectedValue={pastDataIgnoreThreshold}
              onValueChange={setPastDataIgnoreThreshold}
            >
              {PAST_DATA_IGNORE_OPTIONS.map(option => (
                <Picker.Item key={option} value={option} label={option} />
              ))}
            </StyledPicker>
            <Text>%</Text>
          </Option>

          <Text>
            This can be used to exclude prior orders with only a few tags
          </Text>
          <Text>
            pre-registered from affecting the "% Registered at Reorder" value.
          </Text>
        </Column>
      </PastDataContainer>

      <Title>
        Expected Upcoming Reorders -{' '}
        {delimited(lowUnregistered.length)} Orders,{' '}
        {delimited(lowUnregisteredTags)} Tags
      </Title>

      <Option>
        <Text>Change threshold:</Text>
        <StyledPicker
          selectedValue={lowUnregisteredThreshold}
          onValueChange={setLowUnregisteredThreshold}
        >
          {LOW_UNREGISTERED_OPTIONS.map(option => (
            <Picker.Item key={option} value={option} label={option} />
          ))}
        </StyledPicker>
        <Text>% of tags on last order registered</Text>
      </Option>

      <DataTable.Simple
        pageLength={100}
        data={lowUnregistered}
        columns={columns}
      />
    </View>
  );
};

export default UpcomingReorders;
