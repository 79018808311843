import React, { useContext, useState } from 'react';
import { View, Pressable, Switch } from 'react-native';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';

import { ORDERS } from 'app/src/components/Orders/queries';
import ClientContext from 'app/src/contexts/ClientContext';
import { Button, ButtonGroup } from 'app/src/elements/buttons';
import { Loading } from 'app/src/elements/DataState';
import RelayTable from 'app/src/elements/RelayTable';
import { downloadReport } from 'app/src/elements/ReportDownload';
import { Picker } from 'app/src/elements/Picker';
import { Title, Text, Setting, SettingLabel } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { Actions, Action, ActionText } from 'app/src/styles/table';
import { rest } from 'app/src/utils/rest';
import { Link, useParams } from 'app/src/utils/routing';

import Tag from './show';
import { TAGS } from './queries';

export const columns = [
  {
    key: 'serialNumber',
    label: 'Tag',
    sort: 'asc',
  },
  {
    key: 'dealer',
    label: 'Dealer',
    value: ({ value }) => value.label,
  },
  {
    key: 'purchaser',
    label: 'Purchaser',
  },
  {
    key: 'soldOn',
    label: 'Issued',
    content: ({ value }) => (
      value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT) : ''
    ),
  },
  {
    key: 'vin',
    label: 'VIN',
  },
  {
    key: 'year',
    label: 'Year',
  },
  {
    key: 'makeLabel',
    label: 'Make',
    sort: 'prevent',
  },
  {
    key: 'model',
    label: 'Model',
  },
  {
    key: 'type',
    label: 'Tag Size',
    value: ({ value }) => 'M' === value ? 'Motorcycle' : 'Automobile',
  },
  {
    key: 'actions',
    label: 'Actions',
    sort: 'prevent',
  },
];

const nodes = collection => (
  collection.edges.map(edge => edge.node)
);

export const setupActions = ({ columns, setShowDetail, setShowVoid }) => {
  const actionColumn = { ...columns[columns.length - 1] };

  actionColumn.content = ({ entry }) => {
    const registerPath =
      `/tags/register/${entry.serialNumber}/${entry.type}/${entry.dealer.id}`;

    if (!entry.registeredAt) {
      return (
        <Actions>
          {entry.voidAt ? (
            <Action first>
              <Pressable onPress={() => setShowDetail(entry)}>
                <ActionText>VOIDED - Click to change</ActionText>
              </Pressable>
            </Action>
          ) : (
            <>
              <Action first>
                <Link to={registerPath}>
                  <ActionText>Log Tag</ActionText>
                </Link>
              </Action>

              {entry.canVoid && (
                <Action>
                  <Pressable onPress={() => setShowVoid(entry)}>
                    <ActionText>Void Tag</ActionText>
                  </Pressable>
                </Action>
              )}
            </>
          )}
        </Actions>
      );
    } else {
      return (
        <Actions>
          <Action first>
            <Pressable onPress={() => setShowDetail(entry)}>
              <ActionText>View Details</ActionText>
            </Pressable>
          </Action>

          {entry.canEdit && (
            <Action>
              <Link to={`/tags/${entry.id}/edit`}>
                <ActionText>Edit Details</ActionText>
              </Link>
            </Action>
          )}

          {entry.canVoid && (
            <Action>
              <Pressable onPress={() => setShowVoid(entry)}>
                <ActionText>Void Sale</ActionText>
              </Pressable>
            </Action>
          )}

          {entry.voidAt && (
            <>
              <Action>
                <Pressable onPress={() => setShowDetail(entry)}>
                  <ActionText>VOIDED</ActionText>
                </Pressable>
              </Action>

              {entry.canReregister && (
                <Action>
                  <Link to={registerPath}>
                    <ActionText>Log Tag</ActionText>
                  </Link>
                </Action>
              )}
            </>
          )}
        </Actions>
      );
    }
  };

  return [...columns.slice(0, -1), actionColumn];
};

const orderDescription = order => {
  const filledOn = DateTime.fromISO(order.filledAt).
    toLocaleString(DateTime.DATE_MED);

  return `${filledOn}, Serials ${order.serialSummary}`;
};

const Tags = () => {
  const { credentials } = useContext(ClientContext);
  const [downloading, setDownloading] = useState();
  const [refetch, setRefetch] = useState();
  const [showDetail, setShowDetail] = useState();
  const [showVoid, setShowVoid] = useState();
  const [orderId, setOrderId] = useState(useParams().orderId);
  const [scopes, setScopes] = useState([]);

  const queryVariables = { orderId: (orderId || null), scopes };

  const { data } = useQuery(ORDERS, {
    variables: { limit: 100, offset: window.btoa('0'), options: {} }
  });

  if (!data) { return <Loading />; }

  const handleDownload = downloadProps => {
    downloadReport({
      ...downloadProps,
      path: 'tag-log',
      params: { scopes, 'order-id': orderId }
    })
  };

  const handlePrintLog = () => {
    setDownloading('log');

    rest({
      path: `pdf/summary/${orderId}.pdf`,
      options: { headers: { Accept: 'application/pdf' } },
      credentials,
    }).then(response => {
      response.blob().then(blob => {
        const dataUrl = window.URL.createObjectURL(blob);
        window.open(dataUrl);
        setDownloading(false);
      });
    });
  };

  const orders = nodes(data.orders).reverse().filter(order => order.filledAt);

  let printLogLabel = orderId ?
    'Print Log' :
    'Select Order to Print';

  const buttons = (
    <ButtonGroup>
      <Button
        label={
          'xlsx' === downloading ? '...' : 'Download Detailed Spreadsheet'
        }
        style={{ width: 400, marginTop: -20 }}
        onPress={() => (
          handleDownload({ credentials, type: 'xlsx', setDownloading })
        )}
      />

      <Button
        label={'pdf' === downloading ? '...' : printLogLabel}
        style={{ marginTop: -20 }}
        onPress={handlePrintLog}
        disabled={!orderId}
      />
    </ButtonGroup>
  );

  const columnsWithActions = setupActions({
    columns, setShowDetail, setShowVoid
  });

  return (
    <View>
      <Title>Tag Log</Title>

      <Setting>
        <SettingLabel>Limit to Order</SettingLabel>
        <Picker
          selectedValue={orderId || ""}
          onValueChange={value => setOrderId(value)}
        >
          <Picker.Item value="" label="Choose Order" />
          {orders.map(order => (
            <Picker.Item
              key={order.id}
              value={order.id}
              label={orderDescription(order)}
            />
          ))}
        </Picker>
      </Setting>

      <Setting>
        <SettingLabel>Only Show Tags With Data</SettingLabel>
        <Switch
          value={scopes.includes('registered')}
          onValueChange={value => setScopes(value ? ['registered'] : [])}
          activeTrackColor={colors.lightButton}
          activeThumbColor={colors.button}
        />
      </Setting>

      <RelayTable
        query={TAGS}
        queryName="tags"
        queryVariables={queryVariables}
        columns={columnsWithActions}
        buttons={buttons}
        setRefetch={setRefetch}
      />

      {showDetail && (
        <Tag tag={showDetail} setShow={setShowDetail} refetch={refetch} />
      )}

      {showVoid && (
        <Tag tag={showVoid} setShow={setShowVoid} refetch={refetch} voidTag />
      )}
    </View>
  );
};

export default Tags;
