import React from 'react';
import { View } from 'react-native';
import { Subtitle, Text } from 'app/src/styles';

import Video from 'app/src/elements/Video';

const CreateAccount = () => {
  return (
    <View>
      <Subtitle>Creating an Account</Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          If you have ordered tags from AutoDealersResource.com, your order will
          include an information sheet with a custom sign up URL. Follow that URL
          to expedite the review process.
        </Text>

        <Text>
          See this video for more information.
        </Text>
      </View>

      <Video
        url="https://www.youtube.com/embed/OMfOLt5n1oE?si=-0siZWEb2mf_MI_t"
        title="oklahomatemptag.com account registration"
      />
    </View>
  );
};

export default CreateAccount;
