import React, { useContext, useState } from 'react';
import { View } from 'react-native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button, ButtonGroup } from 'app/src/elements/buttons';
import RelayTable from 'app/src/elements/RelayTable';
import { downloadReport } from 'app/src/elements/ReportDownload';
import { Title, Hint } from 'app/src/styles';
import { useParams } from 'app/src/utils/routing';

import { columns, setupActions } from './index';
import Tag from './show';
import { TAGS } from './queries';

const PreRegistrations = () => {
  const { dealerId } = useParams();
  const { credentials } = useContext(ClientContext);
  const [downloading, setDownloading] = useState();
  const [refetch, setRefetch] = useState();
  const [showDetail, setShowDetail] = useState();
  const [showVoid, setShowVoid] = useState();

  const handleDownload = downloadProps => {
    downloadReport({
      ...downloadProps,
      path: 'preregistration',
    })
  };

  const buttons = (
    <ButtonGroup>
      <Button
        label={
          'xlsx' === downloading ? '...' : 'Download Detailed Spreadsheet'
        }
        style={{ width: 400, marginTop: -20 }}
        onPress={() => (
          handleDownload({ credentials, type: 'xlsx', setDownloading })
        )}
      />
    </ButtonGroup>
  );

  const columnsWithActions = setupActions({
    columns, setShowDetail, setShowVoid
  });

  columnsWithActions[0] = { ...columnsWithActions[0], sort: null };

  return (
    <View>
      <Title>Pre-Registration History</Title>
      <Hint>
        This screen shows similar data as the Tag Log screen,
        but is filtered to show recently submitted pre-registration information.
      </Hint>

      <RelayTable
        query={TAGS}
        queryName="tags"
        queryVariables={{ scopes: ['recently_registered'], dealerId }}
        columns={columnsWithActions}
        buttons={buttons}
        setRefetch={setRefetch}
      />

      {showDetail && (
        <Tag tag={showDetail} setShow={setShowDetail} refetch={refetch} />
      )}

      {showVoid && (
        <Tag tag={showVoid} setShow={setShowVoid} refetch={refetch} voidTag />
      )}
    </View>
  );
};

export default PreRegistrations;
