import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Fields, defaultValues } from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import { Title } from 'app/src/styles';
import { useNavigate, useParams } from 'app/src/utils/routing';

import { DEALER, UPDATE_DEALER } from './queries';
import { FIELDS } from './new';
import LicenseInput from './LicenseInput';

const EditDealer = ({ id, setShow, refetch }) => {
  if (!id) { id = useParams().id; }
  const navigate = useNavigate();

  const { data } = useQuery(DEALER, { variables: { id } });

  const {
    control, handleSubmit, reset, watch, formState: { errors }
  } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  useEffect(() => {
    if (data) {
      const values = defaultValues({ fields: FIELDS, resource: data.dealer });
      reset(values);
    }
  }, [data]);

  const [updateDealer] = useMutation(UPDATE_DEALER, {
    onCompleted: () => {
      if (setShow) {
        setShow(false);
        refetch();
      } else {
        navigate('/dealers')
      }
    }
  });

  const onSubmit = input => {
    updateDealer({ variables: input });
  };

  const licensesAttributes = watch('licensesAttributes');

  return (
    <View>
      <Title>Edit Dealer: {data?.dealer.name}</Title>

      <Fields fields={FIELDS} control={control} errors={errors} />

      <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
    </View>
  );
};

export default EditDealer;
