import React, { useEffect, useState } from 'react';
import { View, TextInput, Pressable } from 'react-native';
import { useLazyQuery } from '@apollo/client';
import styled from 'styled-components/native';

import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

import { SEARCH } from './queries';
import Result from './Result';

const Container = styled(View)`
  flex: 1;
  flex-direction: row;
  background-color: white;
  align-self: center;
  align-items: center;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 400px;
  width: 400px;
`;

const Input = styled(TextInput)`
  outline-width: 0px;
  outline-color: white;
  flex: 1;
`;

const Results = styled(View)`
  position: absolute;
  top: 30px;
  right: -1px;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding-top: 5px;
`;

const Search = () => {
  const [term, setTerm] = useState('');
  const [getResults, { data }] = useLazyQuery(SEARCH);

  useEffect(() => {
    if (term && term.length > 2) {
      getResults({ variables: { term }});
    }
  }, [term]);

  return (
    <Container>
      <Input
        placeholder="Search"
        placeholderTextColor={colors.disabled}
        value={term}
        onChange={e => setTerm(e.target.value)}
      />
      {term.length > 0 && (
        <Pressable onPress={() => setTerm('')}>
          <Text>X</Text>
        </Pressable>
      )}
      {data && term.length > 2 && data.search.length > 0 && (
        <Results>
          {data.search.map(result => (
            <Result
              key={`${result.__typename}-${result.id}`}
              result={result}
              setTerm={setTerm}
            />
          ))}
        </Results>
      )}
    </Container>
  );
};

export default Search;
