import React from 'react';
import { View, Pressable, Modal as ModalBase } from 'react-native';
import styled from 'styled-components/native';

import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

const CenteredView = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalView = styled(View)`
  width: fit-content;
  height: fit-content;
  min-width: 50%;
  min-height: fit-content;
  background-color: white;
`;

const Header = styled(View)`
  background-color: ${colors.header};
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled(Text)`
  color: white;
  font-weight: 600;
`;

const Close = styled(Text)`
  color: white;
  font-weight: 900;
  margin-left: 20px;
`;

const Content = styled(View)`
  padding: 20px;
  max-height: 80vh;
  max-width: 90vw;
  overflow: auto;
`;

const Modal = ({ title, children, setShow, style }) => {
  return (
    <ModalBase
      onRequestClose={() => setShow(false)}
      visible
      transparent
    >
      <CenteredView>
        <ModalView style={style}>
          <Header>
            <Title>{title}</Title>
            <Pressable onPress={() => setShow(false)}>
              <Close>X</Close>
            </Pressable>
          </Header>
          <Content>
            {children}
          </Content>
        </ModalView>
      </CenteredView>
    </ModalBase>
  );
};

export default Modal;
