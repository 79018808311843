import { gql } from '@apollo/client';

import { DEALER_CORE_FIELDS } from 'app/src/components/Dealers/queries';
import { TAG_CORE_FIELDS } from 'app/src/components/Tags/queries';

export const SEARCH = gql`
  ${DEALER_CORE_FIELDS}
  ${TAG_CORE_FIELDS}
  query NoUserLogSearch(
    $term: String!
    $type: String
  ) {
    search(
      term: $term
      type: $type
    ) {
      ... on Dealer {
        ...DealerCoreFields
        licenses {
          id
          licenseString
          inactiveAt
        }
      }
      ... on Tag {
        ...TagCoreFields
        dealer {
          id
          name
        }
      }
      ... on UserDealer {
        id
        fullName
        email
        dealer {
          ...DealerCoreFields
        }
      }
    }
  }
`;
