import { gql } from '@apollo/client';

const CREDENTIAL_FIELDS = gql`
  fragment CredentialFields on Credential {
    accessToken
    client
    expiry
    tokenType
    uid
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    fullName
    email
    role
    isEmployee
    otpType
    needChangePassword
    subscriptionRequiredForNonAdrOn
  }
`;

export const CURRENT_USER = gql`
  ${USER_FIELDS}
  query CurrentUser {
    currentUser {
      ...UserFields
      featureAddressSuggest
      alerts {
        body
        url
      }
      dealers {
        id
        name
        isActive
        hasRecentOrder
        activeMember
        canPreregister
        value: id
        label
        licenses {
          id
          licenseString
          inactiveAt
          value: id
          label: licenseString
        }
      }
      policies {
        model
        action
      }
      products {
        id
        name
        value: id
        label: name
        perPack
      }
      userDealers {
        id
        dealer { id, name }
        admin
      }
    }
    makes {
      value
      label
    }
    unitTypes {
      value
      label
    }
  }
`;

export const LOGIN = gql`
  ${CREDENTIAL_FIELDS}
  mutation UserLogin(
    $email: String!
    $password: String!
    $registrationCode: String
    $totp: String
  ) {
    userLogin(
      email: $email
      password: $password
      registrationCode: $registrationCode
      totp: $totp
    ) {
      credentials {
        ...CredentialFields
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation UserLogout {
    userLogout {
      authenticatable {
        id
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation RegisterUser (
    $email: String!
    $firstName: String!
    $lastName: String!
    $registrationCode: String
  ) {
    registerUser (
      email: $email
      firstName: $firstName
      lastName: $lastName
      registrationCode: $registrationCode
    ) {
      user { id }
      errors { path, message }
    }
  }
`;

export const USER_STATUS = gql`
  query UserStatus(
    $email: String!
  ) {
    userStatus(
      email: $email
    )
  }
`;

export const CONFIRM_USER = gql`
  ${CREDENTIAL_FIELDS}
  mutation CorfirmUser (
    $password: String!
    $passwordConfirmation: String!
    $invitationToken: String!
  ) {
    confirmUser(
      password: $password
      passwordConfirmation: $passwordConfirmation
      invitationToken: $invitationToken
    ) {
      credentials {
        ...CredentialFields
      }
    }
  }
`;

export const SEND_PASSWORD_RESET = gql`
  mutation UserSendPasswordResetWithToken(
    $email: String!
    $redirectUrl: String!
  ) {
    userSendPasswordResetWithToken(
      email: $email
      redirectUrl: $redirectUrl
    ) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  ${CREDENTIAL_FIELDS}
  mutation UserUpdatePasswordWithToken(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    userUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      credentials {
        ...CredentialFields
      }
    }
  }
`;

export const LOGIN_WITH_TOKEN = gql`
  ${CREDENTIAL_FIELDS}
  mutation LoginWithToken($token: String!) {
    loginWithToken(token: $token) {
      credentials {
        ...CredentialFields
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updatePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      errors { path, message }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  ${USER_FIELDS}
  mutation UpdateCurrentUser(
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    updateCurrentUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      user {
        ...UserFields
      }
    }
  }
`;

export const SETUP_TOTP = gql`
  mutation SetupTotp {
    setupTotp {
      svg
      base64
    }
  }
`;

export const VERIFY_TOTP = gql`
  ${USER_FIELDS}
  mutation VerifyTotp(
    $code: String!
    $otpRequiredForLogin: Boolean!
  ) {
    verifyTotp(
      code: $code
      otpRequiredForLogin: $otpRequiredForLogin
    ) {
      success
      user {
        ...UserFields
      }
    }
  }
`;

export const DISABLE_TOTP = gql`
  ${USER_FIELDS}
  mutation DisableTotp($code: String!) {
    disableTotp(code: $code) {
      success
      user {
        ...UserFields
      }
    }
  }
`;

export const REGISTRATION_CODE_DEALER = gql`
  query RegistrationCodeDealer($code: String!) {
    registrationCodeDealer(code: $code) {
      name
      error
      message
    }
  }
`;

export const EMAIL_STATUS = gql`
  query EmailStatus($email: String!) {
    emailStatus(email: $email) {
      email
      unsubscribedAt
      todayMessageNotDelivered
    }
  }
`;
