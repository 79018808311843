import React, { useContext } from 'react';
import { View } from 'react-native';
import { DateTime } from 'luxon';

import ClientContext from 'app/src/contexts/ClientContext';
import RelayTable from 'app/src/elements/RelayTable';
import { Title, Text } from 'app/src/styles';
import { ActionText } from 'app/src/styles/table';
import { Link } from 'app/src/utils/routing';

import { ORDERS } from './queries';
import { hoursSinceFilled } from './utils';

export const columns = [
  {
    key: 'dealer',
    label: 'Dealer',
    value: ({ value }) => value?.name,
  },
  {
    key: 'filledAt',
    label: 'Date',
    content: ({ value }) => (
      value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT) : ''
    ),
  },
  {
    key: 'quantity',
    label: 'Quantity',
  },
  {
    key: 'licenses',
    label: 'License',
    value: ({ entry }) => (
      [
        entry.newLicense?.licenseString,
        entry.usedLicense?.licenseString,
      ].filter(value => value).join(' / ')
    )
  },
  {
    key: 'serialSummary',
    label: 'Serial Numbers',
  },
  {
    key: 'product',
    label: 'Product',
    value: ({ value }) => value.name,
  },
  {
    key: 'shopifyOrderNumber',
    label: 'Order #',
  },
  {
    key: 'actions',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <Link to={`/orders/${entry.id}/tags`}>
        <ActionText>View Tags</ActionText>
      </Link>
    )
  },
  {
    key: 'adminActions',
    label: 'Admin Actions',
    sort: 'prevent',
    hidden: true,
    content: ({ entry }) => (
      <View style={{ flexDirection: 'row', gap: 10 }}>
        {(hoursSinceFilled(entry) || 0) < (7 * 24) ? (
          <>
            <Link to={`/orders/${entry.id}/edit`}>
              <ActionText>Edit</ActionText>
            </Link>
            <Link to={`/orders/${entry.id}/print`}>
              <ActionText>Print</ActionText>
            </Link>
          </>
        ) : (
          <Link to={`/orders/${entry.id}`}>
            <ActionText>View</ActionText>
          </Link>
        )}
      </View>
    )
  },
];

const Orders = () => {
  const { currentUser, hasPolicy } = useContext(ClientContext);

  if (currentUser.isEmployee) {
    columns[1].content = ({ value }) => (
      value ?
        DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT) :
        'Not Printed'
    );

    columns[columns.length - 3].hidden = false;
    columns[columns.length - 1].hidden = false;
  }

  return (
    <View>
      <Title>Orders</Title>

      {'dealer' === currentUser.role && (
        <Text>
          New orders do not appear in this list until they are printed.
        </Text>
      )}

      {currentUser?.isEmployee && (
        <View
          style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 20 }}
        >
          <Link to="/orders/upcoming-reorders">
            <Text>⤇ Expected Upcoming Reorders</Text>
          </Link>

          <Link to="/orders/shopify">
            <Text>⤇ Shopify Order Items</Text>
          </Link>
        </View>
      )}

      <RelayTable
        query={ORDERS}
        queryName="orders"
        columns={columns}
      />
    </View>
  );
};

export default Orders;
