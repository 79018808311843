import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';

import ClientContext from 'app/src/contexts/ClientContext';
import { Title } from 'app/src/styles';
import { Notice } from 'app/src/elements/Notice';

import formatAuthErrors from './formatAuthErrors';
import { ActionLink, ActionLabel } from './ActionLink';
import { LOGIN_WITH_TOKEN } from './queries';

const LoginToken = ({ token }) => {
  const [formStatus, setFormStatus] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { updateCredentials } = useContext(ClientContext);

  const [loginWithToken] = useMutation(LOGIN_WITH_TOKEN, {
    onCompleted: data => {
      updateCredentials(data.loginWithToken.credentials);
      window.history.pushState({}, document.title, window.location.pathname);
    },
    onError: error => {
      setErrorMessage(formatAuthErrors(error.graphQLErrors));
      setFormStatus(null);
    }
  });

  useEffect(() => {
    loginWithToken({ variables: { token } });
  }, [token]);

  return (
    <View>
      <Title>Logging In...</Title>
      {errorMessage && <Notice type="error">{errorMessage}</Notice>}

      <ActionLink onPress={() => window.location.href = '/'}>
        <ActionLabel>Return to Login</ActionLabel>
      </ActionLink>
    </View>
  );
};

export default LoginToken
