import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { View } from 'react-native';

import { Title, Text } from 'app/src/styles';
import { Notice } from 'app/src/elements/Notice';

import ClientContext from 'app/src/contexts/ClientContext';
import formatAuthErrors from './formatAuthErrors';
import { ActionLink, ActionLabel } from './ActionLink';
import PasswordForm from './PasswordForm';
import { UPDATE_PASSWORD } from './queries';

const ChangePassword = ({ expired }) => {
  const { refetchCurrentUser } = useContext(ClientContext);
  const [formStatus, setFormStatus] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    onCompleted: data => {
      if (data.updatePassword.errors && data.updatePassword.errors.length > 0) {
        setErrorMessage(data.updatePassword.errors[0].message);
        setFormStatus(null);
      } else {
        setFormStatus('success');
      }
    },
    onError: error => {
      console.log(error);
      setErrorMessage(formatAuthErrors(error.graphQLErrors));
      setFormStatus(null);
    }
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    updatePassword({ variables: input });
  };

  if ('success' === formStatus) {
    if (expired) {
      refetchCurrentUser();
    }

    return (
      <Notice type="success">
        Your password has been updated.
      </Notice>
    );
  }

  return (
    <View>
      {expired ? (
        <View>
          <Title>Password Expired</Title>
          <Notice>
            Your password has expired. You must create a new password. You
            may not use a password that you've previously used in the past year.
          </Notice>
        </View>
      ) : (
        <Title>Change Your Password</Title>
      )}
      {errorMessage && <Notice type="error">{errorMessage}</Notice>}
      <PasswordForm
        onSubmit={onSubmit}
        formStatus={formStatus}
        currentPassword
      />
    </View>
  );
};

export default ChangePassword;
