import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Dimensions } from 'react-native';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import config from 'app/src/config';
import storage from 'app/src/utils/storage';
import { sendError } from 'app/src/components/ErrorBoundary';
import { CURRENT_USER, LOGOUT } from 'app/src/components/Auth/queries';

const IGNORE_ERRORS = ['USER_ERROR', 'NOT_FOUND'];

const MOBILE_WIDTH = 800;

export const ClientContext = createContext();

const setupApolloClient = ({ uri, credentials, clearCredentials }) => {
  let headers = {};

  if (credentials) {
    headers = {
      ...credentials,
      'access-token': credentials.accessToken,
    };
  }

  const uploadLink = createUploadLink({
    headers,
    uri: (uri || config.api.uri)
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      if (401 === networkError.statusCode) {
        clearCredentials();
      }
    } else if (graphQLErrors && graphQLErrors[0]) {
      if (!IGNORE_ERRORS.includes(graphQLErrors[0].extensions?.code)) {
        sendError({
          error: {
            name: 'GraphQL Error',
            message: graphQLErrors[0].message,
            stack: JSON.stringify(graphQLErrors),
          }
        });
      }
    }
  });

  const checkHeadersLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(data => {
      const context = operation.getContext();

      // TODO Native support
      if ('undefined' !== typeof(localStorage)) {
        if (context.response?.headers) {
          localStorage.setItem(
            'X-Request-Id',
            context.response.headers.get('X-Request-Id')
          );
        }
      }

      return data;
    });
  });

  return new ApolloClient({
    link: from([checkHeadersLink, errorLink, uploadLink]),
    cache: new InMemoryCache(),
  });
};

export const ClientProvider = ({ children }) => {
  const [apolloClient, setApolloClient] = useState();
  const [credentials, setCredentials] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [makes, setMakes] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [storageChecked, setStorageChecked] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const clearCredentials = () => {
    updateCredentials(null);
  };

  const apolloClientForAuth = useMemo(() => (
    setupApolloClient({
      credentials,
      clearCredentials,
      uri: config.api.baseUri + '/auth',
    })
  ));

  useEffect(() => {
    if (credentials?.accessToken) {
      setApolloClient(setupApolloClient({ credentials, clearCredentials }));
    } else {
      setApolloClient(null);
    }
  }, [credentials]);

  const updateCredentials = value => {
    const data = { credentials: value };

    storage.save({ key: 'auth', data }).then(() => {
      if (credentials?.accessToken !== value?.accessToken) {
        setCredentials(value);
      }
    });

    if (!value) {
      setCurrentUser(null);
    }
  };

  useEffect(() => {
    storage.load({ key: 'auth' }).then(authData => {
      if (authData?.credentials) {
        setCredentials(authData.credentials);
      }

      setStorageChecked(true);
    });
  }, []);

  const refetchCurrentUser = useCallback(() => {
    if (apolloClient && credentials?.accessToken) {
      apolloClient.query({
        query: CURRENT_USER,
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        setCurrentUser(data.currentUser);
        setMakes(data.makes);
        setUnitTypes(data.unitTypes);

        if (!data.currentUser) {
          setCredentials(null);
        }
      });
    }
  }, [apolloClient]);

  const logout = useCallback(() => {
    updateCredentials(null);
    apolloClientForAuth.mutate({ mutation: LOGOUT }).
      then(() => window.location.href = '/');
  });

  React.useEffect(() => {
    setIsMobile(Dimensions.get('window').width <= MOBILE_WIDTH);

    const handleChange = ({ window: win }) => {
      if (win.width <= MOBILE_WIDTH) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    const subscription = Dimensions.addEventListener('change', handleChange);

    return () => {
      subscription.remove();
    };
  }, [setIsMobile]);

  const isLoggedIn = !!credentials?.accessToken;

  if (!currentUser && isLoggedIn) {
    refetchCurrentUser();
  }

  const hasPolicy = ({ type, action }) => {
    if (!currentUser) { return false; }

    return currentUser.policies.find(policy => (
      policy.model === type && policy.action === action
    ));
  };

  const activeDealers = currentUser ?
    currentUser.dealers.filter(dealer => dealer.isActive) : [];

  const inactiveDealers = currentUser ?
    currentUser.dealers.filter(dealer => !dealer.isActive) : [];

  const activeProducts = currentUser ? currentUser.products : [];

  const value = {
    apolloClient,
    apolloClientForAuth,
    updateCredentials,
    isLoggedIn,
    logout,
    currentUser,
    refetchCurrentUser,
    activeDealers,
    inactiveDealers,
    activeProducts,
    makes,
    unitTypes,
    hasPolicy,
    storageChecked,
    credentials,
    isMobile,
  };

  return (
    <ClientContext.Provider value={value}>
      {children}
    </ClientContext.Provider>
  );
};

export default ClientContext;
