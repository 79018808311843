import { ORDER_FIELDS } from './new';

const setupOrderFields = ({ dealer, values, activeProducts }) => {
  if (!dealer) { return ORDER_FIELDS; }

  const orderFields = { ...ORDER_FIELDS };

  const activeLicenses = dealer ?
    dealer.licenses.filter(license => !license.inactiveAt) : [];

  orderFields.productId.inputProps = { dealer, products: activeProducts };

  if ('full' === values.template) {
    orderFields.newLicenseId = {
      label: 'New License',
      select: [{ value: '', label: 'Select'}, ...activeLicenses],
      rules: {
        validate: value => {
          if (!values.usedLicenseId && !value) {
            return 'At least one license is required.';
          }
        }
      }
    };

    orderFields.usedLicenseId = {
      label: 'Used License',
      select: [{ value: '', label: 'Select'}, ...activeLicenses],
      rules: {
        validate: value => {
          if (!values.newLicenseId && !value) {
            return 'At least one license is required';
          }

          if (values.newLicenseId === value) {
            return 'Licenses cannot be the same. Enter only the new license ' +
              'field if you use the same license for new and used';
          }
        }
      }
    };

    if (values.productId) {
      const product =
        activeProducts.find(option => option.id === values.productId);

      if (product?.perPack) {
        orderFields.quantity.inputProps = {
          multiplierLabel: `or Packages of ${product.perPack}`,
          multiplier: product.perPack,
        };
      }
    }
  }

  return orderFields;
};

export default setupOrderFields;
