import React from 'react';
import { View, Pressable } from 'react-native';

import EditFeatureSubscription from 'app/src/components/FeatureSubscriptions/edit';
import EditableDetails from 'app/src/elements/EditableDetails';
import useToggle from 'app/src/hooks/useToggle';
import { Subtitle, Text } from 'app/src/styles';

const FeatureSubscriptions = ({ dealer, refetch }) => {
  const [showCancelled, toggleShowCancelled] = useToggle(false);

  if (0 === dealer.featureSubscriptions.length) { return null; }

  const activeSubscriptions =
    dealer.featureSubscriptions.filter(subscription => !subscription.deletedAt);

  const cancelledSubscriptions =
    dealer.featureSubscriptions.filter(subscription => subscription.deletedAt);

  return (
    <View>
      <Subtitle>Subscriptions</Subtitle>

      {cancelledSubscriptions.length > 0 && (
        <Pressable onPress={toggleShowCancelled}>
          <Text>{showCancelled ? 'Hide Cancelled' : 'Show Cancelled'}</Text>
        </Pressable>
      )}

      {activeSubscriptions.map(featureSubscription => (
        <EditableDetails
          key={featureSubscription.id}
          modalContent={EditFeatureSubscription}
          title="Update Subscription"
          featureSubscription={featureSubscription}
          refetch={refetch}
          canEdit
        >
          <Text>{featureSubscription.fullStatus}</Text>
        </EditableDetails>
      ))}

      {showCancelled && (
        <>
          {cancelledSubscriptions.map(featureSubscription => (
            <View key={featureSubscription.id}>
              <Text>{featureSubscription.fullStatus}</Text>
            </View>
          ))}
        </>
      )}
    </View>
  );
};

export default FeatureSubscriptions;
