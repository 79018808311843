import { gql } from '@apollo/client';

export const IMAGE_REPEAT_OPTIONS = gql`
  query ImageRepeatOptions {
    imageRepeatOptions {
      path
      type
    }
  }
`;
