import React, { useContext } from 'react';
import { View } from 'react-native';
import { DateTime } from 'luxon';
import DataTable from '@preflighttech/preflight-tables';

import { columns as orderColumns } from 'app/src/components/Orders';
import ClientContext from 'app/src/contexts/ClientContext';
import { Title, Text } from 'app/src/styles';
import { ActionText } from 'app/src/styles/table';
import { Link } from 'app/src/utils/routing';

import { ORDERS } from './queries';

const columns = [...orderColumns.slice(1)];
columns[0] = { ...columns[0], sort: 'desc' };

const DealerOrders = ({ orders, title }) => {
  const { currentUser } = useContext(ClientContext);

  if (currentUser.isEmployee) {
    columns[columns.length - 1].hidden = false;
  }

  return (
    <View>
      <Title>{title || 'Orders'}</Title>

      <DataTable.Simple
        pageLength={10}
        data={orders}
        columns={columns}
      />
    </View>
  );
};

export default DealerOrders;
