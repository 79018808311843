import React from 'react';

const ExternalLink = ({ url, children, target }) => {
  return (
    <a
      href={url}
      target={target || '_blank'}
      rel="nofollow"
      style={{textDecoration: 'none'}}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
