import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const INVOICE_CORE_FIELDS = gql`
  fragment InvoiceCoreFields on Invoice {
    id
    reference
    endOn
    amount
  }
`;

export const INVOICE_FIELDS = gql`
  ${INVOICE_CORE_FIELDS}
  fragment InvoiceFields on Invoice {
    ...InvoiceCoreFields
    orderCount
    tagCount
    orderAmount
  }
`;

export const INVOICE = gql`
  ${INVOICE_FIELDS}
  query Invoice($id: ID!) {
    invoice(id: $id) {
      ...InvoiceFields
    }
  }
`;

export const PENDING_INVOICE = gql`
  ${INVOICE_FIELDS}
  query PendingInvoice {
    pendingInvoice {
      ...InvoiceFields
    }
  }
`;

export const INVOICES = gql`
  ${INVOICE_FIELDS}
  query Invoices (
    $limit: Int!
    $offset: String!
    $options: ConnectionOptionsInput
  ) {
    invoices (
      first: $limit
      after: $offset
      options: $options
    ) {
      edges {
        node {
          ...InvoiceFields
        }
      }
      totalCount
    }
  }
`;

const INVOICE_ARGUMENTS = `
  $reference: String!
  $endOn: String!
  $amount: String!
`;

const INVOICE_VARIABLES = `
  reference: $reference
  endOn: $endOn
  amount: $amount
`;

export const CREATE_INVOICE = gql`
  ${INVOICE_CORE_FIELDS}
  mutation CreateInvoice (
    ${INVOICE_ARGUMENTS}
  ) {
    createInvoice (
      ${INVOICE_VARIABLES}
    ) {
      invoice {
        ...InvoiceCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const DESTROY_INVOICE = gql`
  mutation DestroyInvoice ($id: ID!) {
    destroyInvoice (id: $id) {
      ${ERRORS}
    }
  }
`;
