import React from 'react';
import { View } from 'react-native';
import { Subtitle, Text } from 'app/src/styles';

const EmailAndPhone = () => {
  return (
    <View>
      <Subtitle>Purchaser Phone and Email</Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          Purchaser email and phone information are requested by Service
          Oklahoma. Service Oklahoma will use a provided email to inform the
          purchaser of updates and next steps. Service Oklahoma may also use
          the purchaser email or phone number to contact the purchase if there
          are any issues with pre-registration.
        </Text>

        <Text>
          Purchase email and phone number are not required to complete
          pre-registration.
        </Text>
      </View>
    </View>
  );
};

export default EmailAndPhone;
