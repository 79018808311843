import React, { useState } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Button } from 'app/src/elements/buttons';
import { StyledTextInput } from 'app/src/elements/inputs';
import Modal from 'app/src/elements/Modal';
import { Text } from 'app/src/styles';

const Field = styled(View)`
  flex-direction: row;
  align-items: baseline;
  gap: 6px;
`;

const Label = styled(Text)`
`;

const CalculateSerials = ({ order, setShow, onCalculated }) => {
  const [topSerial, setTopSerial] = useState('');

  const calculate = () => {
    const startSerial = order.tags[0].serialNum;
    const groupDiff = Math.ceil(order.quantity / 3);
    const diff = parseInt(topSerial, 10) - startSerial - 1;

    const serials = [
      `${startSerial}-${startSerial + diff}`,
      `${startSerial + groupDiff}-${startSerial + groupDiff + diff}`,
      `${startSerial + (groupDiff * 2)}-${startSerial + (groupDiff * 2) + diff}`
    ].join(', ')

    onCalculated(serials);
  }

  return (
    <Modal title="Calcutate Serials to Print" setShow={setShow}>
      <Text>
        If tags stopped printing, enter the last 3 digits of the top tag on
        the top piece of paper to set the remaining serials to print.
      </Text>

      <Text>
        When the preview displays, make sure to look at the tags in the preview
        and compare to the printed tags to verify that the calculation makes
        sense. In particular, scroll to the bottom page of the preview. The
        serials numbers should be 1 less than on the last page actually printed.
      </Text>

      <Field>
        <Label>Last 3 digits of top tag, top paper</Label>
        <StyledTextInput
          style={{ minWidth: 200 }}
          value={topSerial}
          onChangeText={setTopSerial}
        />
      </Field>

      <Button label="Calculate" onPress={calculate} wide />
    </Modal>
  );
};

export default CalculateSerials;
