import React from 'react';
import { View } from 'react-native';

import { Title } from 'app/src/styles';

import About from './About';
import Account from './Account';
import Tags from './Tags';
import Users from './Users';

const AllDocs = () => {
  return (
    <View>
      <Title>How To Use oklahomatemptag.com</Title>
      <About />
      <Account />
      <Users />
      <Tags />
    </View>
  );
};

export default AllDocs;
