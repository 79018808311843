import { Text as BaseText, View } from 'react-native';
import styled from 'styled-components/native';

import colors from './colors';
import fonts from './fonts';

export const Text = styled(BaseText)`
  font-family: '${fonts.body}';
  font-size: 16px;
`;

export const Title = styled(Text)`
  font-family: '${fonts.body}';
  font-size: 22px;

  ${props => !props.withLinks && `
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: black;
    margin-bottom: 12px;
  `}
`;

export const Hint = styled(BaseText)`
  font-size: 14px;
  color: ${colors.button};
  font-style: italic;
  font-weight: 600;
`;

export const Subtitle = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  margin-vertical: 12px;

  ${props => props.bordered ? `
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: black;
  ` : ''}
`;

export const Row = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
`;

export const Column = styled(View)`
  flex: 1;
  min-width: ${props => props.isMobile ? '80vw' : '300px'};
`;

export const Additional = styled(View)`
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding: 20px;
  gap: 30px;
`;

export const Warning = styled(View)`
  margin-top: 12px;
  margin-bottom: 12px;
  border-width: 2px;
  border-color: red;
  border-style: solid;
  padding: 12px;
`;

export const WarningText = styled(Text)`
  font-size: 24px;
`;

export const Setting = styled(View)`
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
`;

export const SettingLabel = styled(Text)`
  min-width: 200px;
`;

export const TitleWithLinks = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: black;
  margin-bottom: 12px;
`;

export const TitleLinks = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
`;
