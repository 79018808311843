import React from 'react';
import { View, Pressable } from 'react-native';
import styled from 'styled-components/native';
import { DateTime } from 'luxon';

import { Link } from 'app/src/utils/routing';
import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { datetimeMedium } from 'app/src/utils/formatting';

const Container = styled(View)`
  flex-direction: row;
  column-gap: 10px;
  margin: 5px;
`;

const Name = styled(View)`
  width: 500px;
`;

const NameText = styled(Text)`
  font-weight: 600;
`;

const UsedText = styled(NameText)`
  font-weight: 600;
  text-decoration: line-through;
`;

const Detail = styled(View)`
  width: 300px;
`;

const SelectResult = ({ result }) => {
  return (
    <Container>
      <Name>
        <NameText>{result.label}</NameText>
      </Name>
    </Container>
  );
};

const DealerResult = ({ result }) => {
  const name = result.licenses
    .filter(license => !license.inactiveAt)
    .map(license => license.licenseString).join('/') +
    ' - ' + result.name;

  return (
    <Container>
      <Name>
        <NameText>{name}</NameText>
      </Name>
      <Detail>
        <Text>{result.city}</Text>
      </Detail>
    </Container>
  );
};

const TagResult = ({ result }) => {
  let content = (
    <NameText>{result.serialNumber}</NameText>
  );

  if (result.voidAt) {
    content = (
      <>
        <NameText>{result.serialNumber}</NameText>
        <Text>Voided {datetimeMedium(result.voidAt)}</Text>
      </>
    );
  } else if (result.registeredAt) {
    content = (
      <>
        <UsedText>{result.serialNumber}</UsedText>
        <Text>Registered {datetimeMedium(result.registeredAt)}</Text>
      </>
    );
  }

  return (
    <Container>
      <Name>
        {content}
      </Name>
    </Container>
  );
};

const Result = ({ result, setSelected }) => {
  let Item = SelectResult;

  if ('Dealer' === result.__typename) {
    Item = DealerResult;
  } else if ('Tag' === result.__typename) {
    Item = TagResult;
    result = { ...result, disabled: (!result.voidAt && result.registeredAt) };
  }

  return (
    <Pressable
      onPress={() => result.disabled ? {} : setSelected(result)}
      style={state => (
        { backgroundColor: state.hovered ? 'lightgray' : 'none' }
      )}
    >
      <Item result={result} />
    </Pressable>
  );
};

export default Result;
