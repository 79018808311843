import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import DataTable from '@preflighttech/preflight-tables';

import ClientContext from 'app/src/contexts/ClientContext';
import { Loading } from 'app/src/elements/DataState';
import minimalTable from 'app/src/elements/minimalTable';
import RelayTable from 'app/src/elements/RelayTable';
import { Title } from 'app/src/styles';
import { Actions, Action, ActionText } from 'app/src/styles/table';
import { currency, delimited } from 'app/src/utils/formatting';
import { rest } from 'app/src/utils/rest';

import FinalizeInvoice from './Finalize';
import { INVOICES, PENDING_INVOICE, DESTROY_INVOICE } from './queries';

const generate = ({ id, credentials }) => {
  let path = 'pdf/invoice.pdf';

  if (id) { path = `${path}?id=${id}`; }

  rest({
    path,
    options: { headers: { Accept: 'application/pdf' } },
    credentials,
  }).then(response => {
    response.blob().then(blob => {
      window.open(window.URL.createObjectURL(blob));
    });
  });
};

const Invoices = () => {
  const { credentials, currentUser } = useContext(ClientContext);
  const [refetch, setRefetch] = useState();

  const [showFinalize, setShowFinalize] = useState();

  const { data, refetch: refetchPending } = useQuery(PENDING_INVOICE);

  const refetchAll = () => {
    refetch();
    refetchPending();
  };

  const [destroyInvoice] = useMutation(DESTROY_INVOICE, {
    onCompleted: refetchAll
  });

  if (!currentUser?.isEmployee) {
    return null;
  }

  if (!data) { return <Loading />; }

  const handleDestroy = invoice => {
    if (window.confirm(`Really destroy invoice ${invoice.reference}?`)) {
      destroyInvoice({ variables: { id: invoice.id } });
    }
  }

  const pendingColumns = [
    {
      key: 'reference',
      label: 'Reference',
      sort: 'prevent',
      value: () => 'Pending Invoice',
    },
    {
      key: 'orderCount',
      label: 'Orders',
      sort: 'prevent',
      cellStyle: { textAlign: 'center' },
      content: delimited,
    },
    {
      key: 'tagCount',
      label: 'Tags',
      sort: 'prevent',
      cellStyle: { textAlign: 'center' },
      content: delimited,
    },
    {
      key: 'orderAmount',
      label: 'Amount from Tags',
      sort: 'prevent',
      cellStyle: { textAlign: 'center' },
      content: currency,
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <Actions>
          <Action first>
            <Pressable onPress={() => generate({ credentials })}>
              <ActionText>PDF</ActionText>
            </Pressable>
          </Action>
          <Action>
            <Pressable onPress={() => setShowFinalize(true)}>
              <ActionText>Finalize</ActionText>
            </Pressable>
          </Action>
        </Actions>
      )
    },
  ];

  const columns = [
    {
      key: 'reference',
      label: 'Reference',
    },
    {
      key: 'endOn',
      label: 'End Date',
      sort: 'desc',
    },
    {
      key: 'amount',
      label: 'Amount',
      cellStyle: { textAlign: 'right' },
      content: currency,
    },
    {
      key: 'orderCount',
      label: 'Orders',
      sort: 'prevent',
      cellStyle: { textAlign: 'right' },
      content: delimited,
    },
    {
      key: 'tagCount',
      label: 'Tags',
      sort: 'prevent',
      cellStyle: { textAlign: 'right' },
      content: delimited,
    },
    {
      key: 'orderAmount',
      label: 'Amount from Tags',
      sort: 'prevent',
      cellStyle: { textAlign: 'right' },
      content: currency,
    },
    {
      key: 'otherAmount',
      label: 'Other Amount',
      sort: 'prevent',
      cellStyle: { textAlign: 'right' },
      value: ({ entry }) => (
        parseFloat(entry.amount) - parseFloat(entry.orderAmount)
      ),
      content: currency,
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <Actions>
          <Action first>
            <Pressable onPress={() => generate({ id: entry.id, credentials })}>
              <ActionText>PDF</ActionText>
            </Pressable>
          </Action>
          {entry.endOn > DateTime.now().minus({ days: 10 }).toISODate() && (
            <Action>
              <Pressable onPress={() => handleDestroy(entry)}>
                <ActionText>Delete</ActionText>
              </Pressable>
            </Action>
          )}
        </Actions>
      )
    },
  ];

  return (
    <View>
      <Title>Pending Invoice</Title>

      <DataTable.Simple
        data={[data.pendingInvoice]}
        columns={pendingColumns}
        component={minimalTable}
        pageLength="All"
      />

      <Title style={{ marginTop: 20 }}>Past Invoices</Title>

      <RelayTable
        query={INVOICES}
        queryName="invoices"
        columns={columns}
        pageLength={10}
        setRefetch={setRefetch}
      />

      {showFinalize && (
        <FinalizeInvoice
          setShow={setShowFinalize}
          refetch={refetchAll}
        />
      )}
    </View>
  );
};

export default Invoices;
