import React from 'react';
import { View } from 'react-native';
import { useQuery } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';

import { Loading } from 'app/src/elements/DataState';
import { Title } from 'app/src/styles';
import { ActionText } from 'app/src/styles/table';
import { Link } from 'app/src/utils/routing';

import { SHOPIFY_ITEMS } from './queries';

const columns = [
  {
    key: 'orderNumber',
    label: 'Shopify Order',
  },
  {
    key: 'productName',
    label: 'Product',
  },
  {
    key: 'productId',
    label: 'Product ID',
  },
  {
    key: 'printedName',
    label: 'Name on Tags',
  },
  {
    key: 'licenseNumber',
    label: 'License #',
  },
  {
    key: 'filledAt',
    label: 'Printed At',
    content: ({ entry }) => {
      if (!entry.order) { return null; }

      return (
        <Link to={`/orders/${entry.order.id}/edit`}>
          <ActionText>{entry.order.filledAt || 'Entered'}</ActionText>
        </Link>
      );
    }
  },
];

const ShopifyItems = () => {
  const { data } = useQuery(SHOPIFY_ITEMS);

  if (!data) { return <Loading />; }

  return (
    <View>
      <Title>Shopify Order Items</Title>

      <DataTable.Simple
        pageLength={10}
        data={data.shopifyItems}
        columns={columns}
      />
    </View>
  );
};

export default ShopifyItems;
