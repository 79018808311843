import React, { useEffect } from 'react';
import { View } from 'react-native';

const FormContainer = ({ onEnter, children }) => {
  useEffect(() => {
    const handleKeyUp = (e) => {
      if (13 === e.keyCode && onEnter) {
        onEnter();
      }
    }

    window.document.addEventListener('keyup', handleKeyUp);

    return () => {
      window.document.removeEventListener('keyup', handleKeyUp);
    }
  }, [onEnter]);

  return (
    <View>{children}</View>
  )
};

export default FormContainer;
