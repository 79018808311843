import React from 'react';
import { View } from 'react-native';

import { Text, Title } from 'app/src/styles';

const ShopifyItem = ({ item }) => {
  return (
    <View>
      <Title>Shopify Order</Title>
      <Text>{item.orderNumber}</Text>
      <Text>{item.productName}</Text>
      <Text>{item.printedName}</Text>
      <Text>{item.licenseNumber}</Text>
    </View>
  );
};

export default ShopifyItem;
