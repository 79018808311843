import React, { useContext } from 'react';

import ClientContext from 'app/src/contexts/ClientContext';
import { Routes, Route } from 'app/src/utils/routing';

import Home from 'app/src/components/Home';

import Dealers from 'app/src/components/Dealers';
import Dealer from 'app/src/components/Dealers/show';
import NewDealer from 'app/src/components/Dealers/new';
import EditDealer from 'app/src/components/Dealers/edit';
import DealersNonAdrPreRegistrations from 'app/src/components/Dealers/NonAdrPreRegistrations';
import DealersNoRecentOrder from 'app/src/components/Dealers/NoRecentOrder';
import DealersNameDiffersLastOrder from 'app/src/components/Dealers/NameDiffersLastOrder';

import Exports from 'app/src/components/Exports';
import Financial from 'app/src/components/Financial';
import ImageRepeat from 'app/src/components/ImageRepeat';

import FeatureSubscriptions from 'app/src/components/FeatureSubscriptions';
import FeatureSubscriptionSuccess from 'app/src/components/FeatureSubscriptions/Success';

import Notes from 'app/src/components/Notes';
import Note from 'app/src/components/Notes/show';

import Orders from 'app/src/components/Orders';
import NewOrder from 'app/src/components/Orders/new';
import Order from 'app/src/components/Orders/show';
import EditOrder from 'app/src/components/Orders/edit';
import OrderPrintSetup from 'app/src/components/Orders/PrintSetup';

import UpcomingReorders from 'app/src/components/Orders/UpcomingReorders';
import ShopifyItems from 'app/src/components/Orders/Shopify';

import Tags from 'app/src/components/Tags';
import PreRegistrations from 'app/src/components/Tags/PreRegistrations';
import NewTag from 'app/src/components/Tags/new';
import EditTag from 'app/src/components/Tags/edit';

import ChangePassword from 'app/src/components/Auth/ChangePassword';
import Profile from 'app/src/components/Auth/Profile';
import SetupTOTP from 'app/src/components/Auth/SetupTOTP';

import Users from 'app/src/components/Users';
import User from 'app/src/components/Users/show';

import Docs from 'app/src/components/Docs';
import AllDocs from 'app/src/components/Docs/All';
import DocRoutes from 'app/src/components/Docs/Routes';

const PrimaryRoutes = () => {
  const { currentUser } = useContext(ClientContext);

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />

      <Route exact path="/dealers">
        <Route exact index element={<Dealers />} />
        <Route exact path=":id" element={<Dealer />} />
        <Route exact path="new" element={<NewDealer />} />
        <Route exact path=":id/edit" element={<EditDealer />} />
        <Route
          exact
          path=":dealerId/pre-registrations"
          element={<PreRegistrations />}
        />

        {currentUser?.isEmployee && (
          <>
            <Route
              exact
              path="non-adr-pre-registrations"
              element={<DealersNonAdrPreRegistrations />}
            />
            <Route
              exact
              path="no-recent-order"
              element={<DealersNoRecentOrder />}
            />
            <Route
              exact
              path="name-differs"
              element={<DealersNameDiffersLastOrder />}
            />
          </>
        )}
      </Route>

      <Route exact path="/tags">
        <Route exact index element={<Tags />} />
        <Route exact path="register" element={<NewTag />} />
        <Route exact path="register/:serialNumber/:type" element={<NewTag />} />
        <Route
          exact
          path="register/:serialNumber/:type/:dealerId"
          element={<NewTag />}
        />
        <Route exact path=":id/edit" element={<EditTag />} />
      </Route>
      <Route exact path="/pre-registrations" element={<PreRegistrations />} />

      <Route path="/how-to/*" prefix="" element={<DocRoutes />} />

      <Route exact path="/account/profile" element={<Profile />} />
      <Route exact path="/account/password" element={<ChangePassword />} />
      <Route exact path="/account/totp" element={<SetupTOTP />} />

      <Route
        exact
        path="/feature-subscriptions"
        element={<FeatureSubscriptions />}
      />
      <Route
        exact
        path="/feature-subscriptions/success"
        element={<FeatureSubscriptionSuccess />}
      />

      <Route exact path="/users" element={<Users />} />

      <Route exact path="/orders">
        <Route exact index element={<Orders />} />
        <Route exact path=":orderId/tags" element={<Tags />} />

        {currentUser?.isEmployee && (
          <>
            <Route exact path="new" element={<NewOrder />} />
            <Route exact path=":id" element={<Order />} />
            <Route exact path=":id/edit" element={<EditOrder />} />
            <Route exact path=":id/print" element={<OrderPrintSetup />} />
            <Route exact path="shopify" element={<ShopifyItems />} />
            <Route
              exact
              path="upcoming-reorders"
              element={<UpcomingReorders />}
            />
          </>
        )}
      </Route>

      {'admin' === currentUser?.role && (
        <>
          <Route
            exact
            path="/dealers/approval-status/pending"
            element={<Dealers approvalStatus="Pending" />}
          />

          <Route exact path="/exports" element={<Exports />} />
          <Route exact path="/financial" element={<Financial />} />
          <Route exact path="/image-repeat" element={<ImageRepeat />} />

          <Route exact path="/notes">
            <Route exact index element={<Notes />} />
            <Route exact path=":id" element={<Note />} />
          </Route>

          <Route exact path="/users/:id" element={<User />} />
        </>
      )}
    </Routes>
  );
};

export default PrimaryRoutes;
