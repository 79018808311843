import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import styled from 'styled-components/native';

import Notice from 'app/src/elements/Notice';
import colors from 'app/src/styles/colors';

import { ErrorMessage } from 'app/src/components/ErrorBoundary';

const ActivityContainer = styled(View)`
  margin-left: auto;
  margin-right: auto;
  padding-top: 6px;
`;

export const Loading = () => {
  return (
    <ActivityContainer>
      <ActivityIndicator size="large" color={colors.notice} />
    </ActivityContainer>
  );
};

export const Error = ({ error }) => {
  const message = error?.message || 'Unknown Error';

  return <ErrorMessage message={message} />;
};

const DataState = ({ data, loading, error, userErrors, children }) => {
  if (loading && !data) {
    return <Loading />;
  }

  if (error) {
    if ('NOT_FOUND' === error.graphQLErrors?.[0]?.extensions?.code) {
      window.location.assign('/');
    }

    return <Error error={error} />;
  }

  return (
    <>
      {userErrors && userErrors.length > 0 && (
        <Notice>
          {userErrors.map(error => (
            `${error.path[error.path.length - 1]} ${error.message}`
          ))}
        </Notice>
      )}
      {children}
    </>
  );
};

export default DataState;
