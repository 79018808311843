import React from 'react';
import { View } from 'react-native';

import PreRegisterSale from './PreRegisterSale';
import EmailAndPhone from './EmailAndPhone';

const TagDocs = () => {
  return (
    <View>
      <PreRegisterSale />
      <EmailAndPhone />
    </View>
  );
};

export default TagDocs;
