import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Button } from 'app/src/elements/buttons';
import { Fields, defaultValues } from 'app/src/elements/forms';
import Modal from 'app/src/elements/Modal';
import { Text } from 'app/src/styles';
import { useNavigate } from 'app/src/utils/routing';

import { DESTROY_ORDER } from './queries';

const PHYSICAL_TAGS_OPTIONS = [
  { value: null, label: 'Select' },
  ...([
    'Tags were not printed',
    'Tags were printed and have been destroyed',
    'Tags were printed and will be destroyed',
    'Other (explain in note above)',
  ].map(option => ({ value: option, label: option })))
];

const FIELDS = {
  note: {
    label: 'Why is this order being destroyed?',
    rules: { required: true },
  },
  physicalTags: {
    label: 'What is the status of the physical tags?',
    rules: { required: true },
    select: PHYSICAL_TAGS_OPTIONS,
  },
};

const DestroyOrder = ({ order, setShow }) => {
  const [formStatus, setFormStatus] = useState();
  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  const [destroyOrder] = useMutation(DESTROY_ORDER, {
    onCompleted: () => navigate('/orders')
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    destroyOrder({ variables: { id: order.id, ...input } });
  };

  return (
    <Modal title="Destroy Order" setShow={setShow}>
      <Fields fields={FIELDS} control={control} errors={errors} />

      {'submitting' === formStatus ? (
        <Text>Processing...</Text>
      ) : (
        <Button label="DESTROY ORDER" onPress={handleSubmit(onSubmit)} wide />
      )}
    </Modal>
  );
};

export default DestroyOrder;
