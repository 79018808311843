import React from 'react';
import { View, Pressable } from 'react-native';
import styled from 'styled-components/native';

import { Link } from 'app/src/utils/routing';
import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

const Container = styled(View)`
  flex-direction: row;
  column-gap: 10px;
  margin: 5px;
`;

const Name = styled(View)`
  width: 400px;
`;

const NameText = styled(Text)`
  font-weight: 600;
  ${props => props.inactive ? 'color: gray;' : ''}
`;

const Detail = styled(View)`
  width: 300px;
`;

const DealerResult = ({ result }) => {
  const name = result.licenses
    .filter(license => !license.inactiveAt)
    .map(license => license.licenseString).join('/') +
    ' - ' + result.name;

  return (
    <Link to={`/dealers/${result.id}`}>
      <Container>
        <Name>
          <NameText inactive={result.inactiveAt}>{name}</NameText>
        </Name>
        <Detail>
          <Text>{result.city}</Text>
        </Detail>
      </Container>
    </Link>
  );
};

const TagResult = ({ result }) => {
  return (
    <Link to={`/dealers/${result.dealer.id}`}>
      <Container>
        <Name>
          <NameText>{result.serialNumber} - {result.vin}</NameText>
        </Name>
        <Detail>
          <Text>{result.dealer.name}</Text>
        </Detail>
      </Container>
    </Link>
  );
};

const UserDealerResult = ({ result }) => {
  return (
    <Link to={`/dealers/${result.dealer.id}`}>
      <Container>
        <Name>
          <NameText>{result.fullName} - {result.email}</NameText>
        </Name>
        <Detail>
          <Text>{result.dealer.name}</Text>
        </Detail>
      </Container>
    </Link>
  );
};

const Result = ({ result, setTerm }) => {
  let ResultLink = TagResult;

  if ('Dealer' === result.__typename) {
    ResultLink = DealerResult;
  } else if ('UserDealer' === result.__typename) {
    ResultLink = UserDealerResult;
  }

  return (
    <Pressable
      onPress={() => setTerm('')}
      style={state => (
        { backgroundColor: state.hovered ? 'lightgray' : 'none' }
      )}
    >
      <ResultLink result={result} />
    </Pressable>
  );
};

export default Result;
