import React, { useContext, useEffect, useState } from 'react';
import { Pressable, View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPenToSquare, faBan } from '@fortawesome/free-solid-svg-icons';

import ClientContext from 'app/src/contexts/ClientContext';
import { useNavigate, useParams } from 'app/src/utils/routing';
import { Fields, defaultValues, prepareInput } from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import { Text, Title, Subtitle, Row, Column } from 'app/src/styles';

import { TAG, UPDATE_TAG } from './queries';
import { PURCHASER_FIELDS, VEHICLE_FIELDS, extractFields } from './new';
import EditSerialNumber from './EditSerialNumber';
import ClearForm from './ClearForm';

const FIELDS = { ...PURCHASER_FIELDS, ...VEHICLE_FIELDS };

const EditTag = () => {
  const { id } = useParams();
  const { currentUser, makes, isMobile } = useContext(ClientContext);
  const navigate = useNavigate();
  const [showEditSerialNumber, setShowEditSerialNumber] = useState(false);
  const [showClearForm, setShowClearForm] = useState(false);

  const { data } = useQuery(TAG, { variables: { id } });

  const {
    control, handleSubmit, reset, setValue, watch, formState: { errors }
  } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  const values = watch();

  useEffect(() => {
    if (data) {
      const values = defaultValues({ fields: FIELDS, resource: data.tag });
      values.makeLabel =
        makes.find(make => make.value === data.tag.make)?.label;
      reset(values);
    }
  }, [data]);

  const [updateTag] = useMutation(UPDATE_TAG, {
    onCompleted: () => navigate('/')
  });

  const onSubmit = input => {
    updateTag({ variables: prepareInput(input, FIELDS) });
  };

  const fields = { ...FIELDS };

  fields.make = { ...fields.make };

  fields.make.autocomplete = {
    clientSelect: 'makes',
    selectedLabel: values.makeLabel || '',
    setSelected: selected => {
      setValue('make', selected?.value);
      setValue('makeLabel', selected?.label);
    }
  };

  return (
    <View>
      <Title>Update Tag Data: {data?.tag.serialNumber}</Title>

      {data?.tag.submittedAt ? (
        <Text>
          Pre-Registration data has been submitted to Service Oklahoma.
          This data can no longer be edited.
        </Text>
      ) : (
        <>
          {data?.tag.registeredAt && (
            <Pressable
              style={{ flexDirection: 'row', gap: 10 }}
              onPress={() => setShowEditSerialNumber(true)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
              <Text style={{ fontSize: 14 }}>Edit Tag Number</Text>
            </Pressable>
          )}

          {data?.tag.registeredAt && !data?.tag.keepTag && (
            <Pressable
              style={{ flexDirection: 'row', gap: 10 }}
              onPress={() => setShowClearForm(true)}
            >
              <FontAwesomeIcon icon={faBan} />
              <Text style={{ fontSize: 14 }}>
                Clear Pre-Registration data associated with this tag.
              </Text>
            </Pressable>
          )}

          <Row>
            <Column isMobile={isMobile}>
              <Subtitle>Vehicle Details</Subtitle>

              <Fields
                fields={extractFields(VEHICLE_FIELDS, fields)}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </Column>

            <Column isMobile={isMobile}>
              <Subtitle>Purchaser Details</Subtitle>

              <Fields
                fields={extractFields(PURCHASER_FIELDS, fields)}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </Column>
          </Row>

          <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
        </>
      )}

      {showEditSerialNumber && (
        <EditSerialNumber
          tag={data.tag}
          setShow={setShowEditSerialNumber}
          onCompleted={newTag => {
            setShowEditSerialNumber(false);
            navigate(`/tags/${newTag.id}/edit`);
          }}
        />
      )}

      {showClearForm && (
        <ClearForm
          tag={data.tag}
          setShow={setShowClearForm}
          onCompleted={() => navigate('/')}
        />
      )}
    </View>
  );
};

export default EditTag;
