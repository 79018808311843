import React, { useContext } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';

import Notes from 'app/src/components/Notes/List';
import CommissionDetails from 'app/src/components/Orders/CommissionDetails';
import ClientContext from 'app/src/contexts/ClientContext';
import DataState from 'app/src/elements/DataState';
import EditableDetails from 'app/src/elements/EditableDetails';
import { Text, Title, TitleLinks, Subtitle, Additional } from 'app/src/styles';
import { cityStateZip } from 'app/src/utils/address';
import { Link, useParams } from 'app/src/utils/routing';

import EditDealer from './edit';
import DealerOrders from './Orders';
import Licenses from './Licenses';
import FeatureSubscriptions from './FeatureSubscriptions';
import Users from './Users';
import { DEALER, UPDATE_DEALER_INACTIVE } from './queries';

const Dealer = () => {
  const { currentUser } = useContext(ClientContext);
  const { id } = useParams();

  const { data, refetch, ...dataState } =
    useQuery(DEALER, { variables: { id } });

  const [updateDealerInactive] = useMutation(UPDATE_DEALER_INACTIVE, {
    onCompleted: refetch
  });

  const activeLicenses = data ?
    data.dealer.licenses.filter(license => !license.inactiveAt) : [];

  const submitInactive = inactive => {
    const confirmation = inactive ?
      'Really make dealer inactive? It will no longer be shown in searches.' :
      'Really re-active dealer?';

    if (window.confirm(confirmation)) {
      updateDealerInactive({ variables: { id, inactive } });
    }
  };

  return (
    <View>
      <DataState data={data} {...dataState}>
        {data?.dealer && (
          <View style={{ flexDirection: 'row', gap: 24 }}>
            <View style={{ flex: 1 }}>
              <Title>{data?.dealer.name}</Title>
              <TitleLinks>

                {currentUser.isEmployee && (
                  data.dealer.inactiveAt ? (
                    <Pressable onPress={() => submitInactive(false)}>
                      <Text>⤇ INACTIVE - Reactivate</Text>
                    </Pressable>
                  ) : (
                    <Pressable onPress={() => submitInactive(true)}>
                      <Text>⤇ Make Inactive</Text>
                    </Pressable>
                  )
                )}

                <Link to={`/dealers/${id}/pre-registrations`}>
                  <Text>⤇ Pre-Registration History</Text>
                </Link>
              </TitleLinks>

              <EditableDetails
                modalContent={EditDealer}
                title="Update Dealer"
                id={data.dealer.id}
                canEdit
                refetch={refetch}
              >
                <Text>{data.dealer.street}</Text>
                <Text>{cityStateZip(data.dealer)}</Text>
                <Text>{data.dealer.phone}</Text>
                <Text>{data.dealer.url}</Text>
              </EditableDetails>

              <Subtitle>Licenses</Subtitle>
              <EditableDetails
                modalContent={Licenses}
                title="Dealer Licenses"
                id={data.dealer.id}
                canEdit={currentUser.isEmployee}
                refetch={refetch}
              >
                {activeLicenses.map(license => (
                  <View
                    key={license.id}
                    style={{ flexDirection: 'row', gap: 10 }}
                  >
                    <Text>{license.licenseType}</Text>
                    <Text>{license.licenseNumber}</Text>
                  </View>
                ))}
              </EditableDetails>

              <FeatureSubscriptions dealer={data?.dealer} refetch={refetch} />

              {currentUser?.isEmployee && (
                <View>
                  <Subtitle bordered>Users</Subtitle>
                  <Users dealer={data.dealer} refetch={refetch} />

                  <Subtitle bordered>Notes</Subtitle>
                  <Notes
                    notes={data.dealer.notes}
                    dealer={data.dealer}
                    refetch={refetch}
                  />
                </View>
              )}
            </View>

            <Additional>
              {currentUser?.isEmployee && (
                <CommissionDetails dealer={data.dealer} />
              )}

              <DealerOrders title="Past Orders" orders={data.dealer.orders} />
            </Additional>
          </View>
        )}
      </DataState>
    </View>
  );
};

export default Dealer;
