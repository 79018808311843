import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Text } from './index';
import fonts from './fonts';

const tableStyles = {
  header: {
    container: {
      backgroundColor: 'black',
      paddingVertical: 4,
    },
    label: {
      fontFamily: fonts.header,
      color: 'white',
    }
  },
  data: {
    container: {
      backgroundColor: '#f6f6ff',
      paddingVertical: 2,
      paddingHorizontal: 4,
    },
    alternate: {
      backgroundColor: '#e9e9f0',
    },
    cell: {
      paddingVertical: 2,
      paddingHorizontal: 4,
    },
    content: {
      fontSize: 14,
      fontFamily: fonts.body,
    }
  }
};

export const Actions = styled(View)`
  flex-direction: row;
`;

export const Action = styled(View)`
  ${props => props.first ? '' : `
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: black;
    padding-left: 10px;
    margin-left: 10px;
  `}
`;

export const ActionText = ({ children }) => {
  const style = { fontSize: 14 };

  return (
    <Text style={{ fontSize: 14 }} dataSet={{ actionText: 'true' }}>
      {children}
    </Text>
  );
};

export const CellText = styled(Text)`
  font-size: 14px;
`;

export default tableStyles;
