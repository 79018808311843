import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery } from '@apollo/client';

import DataState from 'app/src/elements/DataState';
import { Text, Warning, WarningText } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

import Tag from './show';
import { TAG } from './queries';

const VoidableError = ({ error, onVoidCompleted }) => {
  const [showVoid, setShowVoid] = useState();

  const { data } = useQuery(TAG, { variables: { id: error.path[2] } });

  if (!data) { return null; }

  return (
    <View>
      <WarningText style={{ fontSize: 20 }}>
        Void the previous pre-registration to continue.
      </WarningText>

      {data.tag.canVoid && (
        <Pressable onPress={() => setShowVoid(data.tag)}>
          <Text>
            ➔ Click here to review prior pre-registration.
          </Text>
        </Pressable>
      )}

      {data.tag.canEdit && (
        <Link to={`/tags/${data.tag.id}/edit`}>
          <Text>
            ➔ Data for the prior pre-registration has not yet been submitted to
            the state, so you can click here to go to the edit screen if you
            need to make a change.
          </Text>
        </Link>
      )}

      {showVoid && (
        <Tag
          tag={showVoid}
          setShow={setShowVoid}
          refetch={onVoidCompleted}
          voidTag
        />
      )}
    </View>
  );
};

const PreRegistrationErrors = ({ errors, onVoidCompleted }) => {
  return (
    <Warning style={{ gap: 12 }}>
      {errors.map(error => (
        <View key={error}>
          <WarningText style={{ fontSize: 20 }}>{error.message}</WarningText>

          {'duplicate' === error.path[0] && (
            <VoidableError error={error} onVoidCompleted={onVoidCompleted} />
          )}
        </View>
      ))}
    </Warning>
  );
};

export default PreRegistrationErrors;
