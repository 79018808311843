import React, { useContext } from 'react';
import { View } from 'react-native';
import styled from 'styled-components';

import Dashboard from 'app/src/components/Dashboard';
import DisplayAlerts from 'app/src/components/Alerts/Display';
import PendingDealer from 'app/src/components/Dealers/Pending';
import config from 'app/src/config';
import ClientContext from 'app/src/contexts/ClientContext';
import Video from 'app/src/elements/Video';
import { Title, Subtitle, Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import fonts from 'app/src/styles/fonts';
import { Link } from 'app/src/utils/routing';

const ActionButton = styled(Link)`
  background-color: ${colors.button};
  font-family: ${fonts.body};
  font-size: 22px;
  color: white;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 20px;
`;

const Home = () => {
  const { currentUser, activeDealers } = useContext(ClientContext);

  let content = null;

  if (currentUser.isEmployee) {
    content = <Dashboard />;
  }

  if ('dealer' === currentUser?.role) {
    const videoUrl = config.app.isDemo ?
      'https://drive.google.com/file/d/1_erIsMPXu-3z5A2clTaUxXQJrGpqbMjm' :
      null;

    if (0 === activeDealers.length) {
      content = <PendingDealer />;
    } else {
      content = (
        <>
          <DisplayAlerts />

          <ActionButton to="/tags/register">Pre-Register Sale</ActionButton>

          <ActionButton to="/tags">View Tag Log</ActionButton>
        </>
      );
    }
  }

  return (
    <View>
      <Title>Welcome, {currentUser?.firstName}</Title>
      {content}
    </View>
  );
};

export default Home;
