import React from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Button } from 'app/src/elements/buttons';
import { Fields, defaultValues } from 'app/src/elements/forms';
import { Subtitle, Text } from 'app/src/styles';

import { VOID_TAG } from './queries';

export const FIELDS = {
  reason: {
    label: 'Void Reason',
  },
};

const VoidForm = ({ tag, onVoidChange }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: { id: tag.id, reason: '' }
  });

  const [voidTag] = useMutation(VOID_TAG, { onCompleted: onVoidChange });

  const onSubmit = input => {
    voidTag({ variables: input });
  };

  let title = 'Void Pre-Registration';
  let Notice = () => (
    <>
      <Text>
        Clicking the button below will notify Service Oklahoma that this sale
        has been voided,
      </Text>
      <Text>
        or that the pre-registration information was incorrect and will be
        updated.
      </Text>
      <Text>
        You may include a reason below but the reason is not transmitted to
        Service Oklahoma.
      </Text>
    </>
  );

  if (!tag.submittedAt) {
    Notice = () => (
      <>
        <Text>
          This pre-registration has not yet been submitted to Service Oklahoma.
        </Text>
        <Text>
          Clicking the button below will mark is as void and no information will
          be submitted.
        </Text>
        <Text>
          You may include a reason below for your records.
        </Text>
      </>
    );
  }

  if (!tag.registeredAt) {
    title = 'Void Temporary Tag';
    Notice = () => (
      <>
        <Text>
          Click the button below to mark that this tag will not be provided to a
          customer,
        </Text>
        <Text>
          for example if there was an error in filling out the tag, or
          it has been damaged.
        </Text>
        <Text>
          You may include a reason below for your records.
        </Text>
      </>
    );
  }

  return (
    <View>
      <Subtitle>{title}</Subtitle>
      <Notice />

      <View style={{ height: 10 }}></View>

      <Fields fields={FIELDS} control={control} />

      <Button label={title} onPress={handleSubmit(onSubmit)} wide />
    </View>
  );
};

export default VoidForm;
