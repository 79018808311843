import React, { forwardRef } from 'react';
import styled from 'styled-components/native';
import { Controller } from 'react-hook-form';
import {
  Platform,
  View,
  Switch,
  TextInput as BaseTextInput
} from 'react-native';

import DocsCard from 'app/src/components/Docs/Card';
import { Text, Hint } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import fonts from 'app/src/styles/fonts';

import { DateTimePicker } from './DateTimePicker';
import { Picker } from './Picker';

export const StyledTextInput = styled(BaseTextInput)`
  border-width: 1px;
  border-color: black;
  padding: 6px;
`;

export const StyledPicker = styled(Picker)`
  border-color: black;
  padding: 3px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
`;

const ErrorText = styled(Text)`
  padding-left: 10px;
  color: red;
`;

export const Field = styled(View)`
  margin-bottom: 10px;
`;

export const SwitchField = styled(View)`
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 10px;
  align-items: center;
`;

const SwitchLabel = styled(Text)`
  margin-left: 10px;
`;

export const valueFromPath = (object, path) => (
  path.split('.').reduce((o, k) => (o || {})[k], object)
);

export const FieldLabel = ({ name, label, hint, docs, errors }) => {
  let errorMessage;

  const error = valueFromPath(errors || {}, name);

  if (error) {
    errorMessage = error.message || 'Required';
  }

  return (
    <>
      <View style={{ flexDirection: 'row', gap: 16, alignItems: 'baseline' }}>
        <Text>{label}</Text>
        {errorMessage && <ErrorText>{errorMessage}.</ErrorText>}
        {hint && <Hint>{hint}</Hint>}
        {docs && <DocsCard {...docs} />}
      </View>
    </>
  );
};

export const TextInput = forwardRef((props, ref) => {
  const {
    label,
    hint,
    docs,
    control,
    errors,
    placeholder,
    secureTextEntry,
    handleKeyPress,
    autoFocus,
    rows,
    ...controllerProps
  } = props;

  return (
    <Field>
      <FieldLabel
        name={props.name}
        label={label}
        hint={hint}
        docs={docs}
        errors={errors}
      />
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <StyledTextInput
            {...field}
            ref={ref}
            secureTextEntry={secureTextEntry}
            placeholder={placeholder}
            onChangeText={field.onChange}
            onKeyPress={handleKeyPress}
            autoFocus={autoFocus}
            rows={rows}
            multiline={!!rows}
          />
        )}
      />
    </Field>
  );
});

export const MultiplierInput = props => {
  const {
    label,
    hint,
    docs,
    control,
    errors,
    multiplierLabel,
    multiplier,
    ...controllerProps
  } = props;

  return (
    <Field>
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => {
          const baseValue = parseInt(field.value || '0');
          let multiplierValue = 0;
          if (baseValue && 0 === parseInt(baseValue) % multiplier) {
            multiplierValue = baseValue / multiplier;
          }

          return (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              <View>
                <FieldLabel
                  name={props.name}
                  label={label}
                  hint={hint}
                  docs={docs}
                  errors={errors}
                />
                <StyledTextInput
                  {...field}
                  onChangeText={field.onChange}
                />
              </View>
              <View>
                <Text>{multiplierLabel}</Text>
                <StyledTextInput
                  value={multiplierValue || ''}
                  onChangeText={value => (
                    field.onChange(parseInt(value) * (multiplier || 0) || '')
                  )}
                />
              </View>
            </View>
          );
        }}
      />
    </Field>
  );
};

export const DateInput = forwardRef((props, ref) => {
  const {
    label,
    hint,
    docs,
    control,
    errors,
    options,
    ...controllerProps
  } = props;

  return (
    <Field>
      <FieldLabel
        name={props.name}
        label={label}
        hint={hint}
        docs={docs}
        errors={errors}
      />
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => {
          const onChange = (e, valueOnNative) => {
            if ('web' === Platform.OS) {
              field.onChange(e.target.value);
            } else {
              field.onChange(valueOnNative);
            }
          };
          return (
            <DateTimePicker
              {...field}
              type="date"
              mode="date"
              onChange={onChange}
              ref={ref}
            />
          );
        }}
      />
    </Field>
  );
});

export const PickerInput = props => {
  const {
    label,
    hint,
    docs,
    control,
    errors,
    options,
    ...controllerProps
  } = props;

  return (
    <Field>
      <FieldLabel
        name={props.name}
        label={label}
        hint={hint}
        docs={docs}
        errors={errors}
      />
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <StyledPicker
            {...field}
            selectedValue={field.value}
            onValueChange={field.onChange}
          >
            {options.map(option => (
              <Picker.Item
                key={option.value}
                value={option.value}
                label={option.label}
              />
            ))}
          </StyledPicker>
        )}
      />
    </Field>
  );
};

export const SwitchInput = props => {
  const {
    label,
    control,
    ...controllerProps
  } = props;

  return (
    <SwitchField>
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <Switch
            {...field}
            activeTrackColor={colors.lightButton}
            activeThumbColor={colors.button}
            onValueChange={field.onChange}
          />
        )}
      />
      <SwitchLabel>{label}</SwitchLabel>
    </SwitchField>
  );
};

const Input = {
  Text: TextInput,
  Multiplier: MultiplierInput,
  Date: DateInput,
  Picker: PickerInput,
  Switch: SwitchInput,
};

export default Input;
