import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Fields, defaultValues } from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import { Text } from 'app/src/styles';

import { CREATE_NOTE } from './queries';

export const FIELDS = {
  body: {
    label: 'Note',
    rules: { required: true },
    inputProps:  { rows: 6 },
  },
};

export const EMAIL_FIELDS = {
  emailTo: {
    label: 'Email To:',
    hint: 'Enter Email to and a subject to email this note',
    rules: { required: true },
  },
  emailSubject: {
    label: 'Email Subject:',
    rules: { required: true },
  },
};

const NewNote = ({ dealer, order, user, setShow, refetch }) => {
  const [showEmailFields, setShowEmailFields] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: { ...defaultValues({ fields: FIELDS }) },
  });

  const onSubmit = input => {
    createNote({ variables: {
      ...input,
      dealerId: dealer?.id,
      orderId: order?.id,
      userId: user?.id,
    }});
  };

  const [createNote] = useMutation(CREATE_NOTE, {
    onCompleted: () => {
      refetch();
      setShow(false);
    }
  });

  return (
    <View>
      <Fields fields={FIELDS} control={control} errors={errors} />

      <Pressable onPress={() => setShowEmailFields(!showEmailFields)}>
        <Text>
          [{showEmailFields ? 'Hide email fields' : 'Show email fields'}]
        </Text>
      </Pressable>

      {showEmailFields && (
        <Fields fields={EMAIL_FIELDS} control={control} errors={errors} />
      )}

      <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
    </View>
  );
};

export default NewNote;
