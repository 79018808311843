export const cityStateZip = entity => {
  const stateZip = [entity.state, entity.zip].filter(part => part).join(' ');

  if (entity.city) {
    if (stateZip) {
      return `${entity.city}, ${stateZip}`
    }

    return city;
  }

  return stateZip;
};

export const singleLineAddress = entity => (
  [entity.address1, entity.address2, cityStateZip(entity)]
    .filter(part => part).join(' ')
);
