import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import config from 'app/src/config';
import ClientContext from 'app/src/contexts/ClientContext';
import { Loading } from 'app/src/elements/DataState';
import { useNavigate, useParams } from 'app/src/utils/routing';
import {
  Fields,
  defaultValues,
  PHONE_PATTERN,
  ZIP_CODE_PATTERN,
} from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import { Title } from 'app/src/styles';

import { CREATE_DEALER } from './queries';
import LicenseInput, { LICENSE_RULES, LICENSE_HINT } from './LicenseInput';
import RegisterCode from './RegisterCode';

export const FIELDS = {
  name: {
    label: 'Dealership Name',
    hint: 'Enter the name that will printed on your temporary tags',
    rules: { required: true }
  },
  street: { rules: { required: true } },
  city: { rules: { required: true } },
  state: {
    rules: {
      required: true,
      maxLength: { value: 2, message: 'Use 2-character abbreviation' }
    }
  },
  zip: {
    label: 'Zip Code',
    rules: {
      required: true,
      pattern: {
        value: ZIP_CODE_PATTERN,
        message: 'Enter 5-digit zip code or 9 digit zip+4',
      },
    }
  },
  phone: {
    label: 'Primary Phone',
    rules: {
      pattern: {
        value: PHONE_PATTERN,
        message: 'Enter phone number in format ###-###-####'
      },
    },
  },
  url: { label: 'Website URL', hint: 'Optional' },
};

const NewDealer = ({ pending, onCreated }) => {
  const { currentUser, refetchCurrentUser } = useContext(ClientContext);
  const [formStatus, setFormStatus] = useState();
  const navigate = useNavigate();

  const fields = { ...FIELDS };

  fields.street = {
    ...fields.street,
    type: 'addressStreet',
  };

  fields.note = {
    label: 'Additional Notes',
    hint: 'Optional (such as alternate phone number)',
  };

  const {
    control, handleSubmit, setValue, watch, formState: { errors }
  } = useForm({
    defaultValues: {
      ...defaultValues({ fields }),
      licensesAttributes: [
        { licenseType: '', licenseNumber: '' },
        { licenseType: '', licenseNumber: '' },
      ],
    }
  });

  const [createDealer] = useMutation(CREATE_DEALER, {
    onCompleted: data => {
      if (onCreated) {
        refetchCurrentUser();
        onCreated(data.createDealer.dealer);
      } else if (pending) {
        refetchCurrentUser();
      } else {
        refetchCurrentUser();
        navigate('/dealers');
      }
    }
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    createDealer({ variables: input });
  };

  const licensesAttributes = watch('licensesAttributes');

  return (
    <View>
      {!pending && (
        <Title>Add Dealer</Title>
      )}

      {!currentUser?.isEmployee && <RegisterCode />}

      {licensesAttributes.map((license, index) => (
        <LicenseInput
          key={index}
          license={license}
          index={index}
          control={control}
          errors={errors}
          hint={0 === index ? LICENSE_HINT : 'Optional'}
          rules={0 === index ? LICENSE_RULES : {}}
          checkRegistered={!currentUser?.isEmployee}
        />
      ))}

      <Fields
        fields={fields}
        control={control}
        errors={errors}
        setValue={setValue}
      />

      {'submitting' === formStatus ? (
        <Loading />
      ) : (
        <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
      )}
    </View>
  );
};

export default NewDealer;
