import React, { useState } from 'react';
import { Pressable } from 'react-native-web';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import Modal from 'app/src/elements/Modal';

const Card = ({ title, element: Element }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Pressable onPress={() => setShowModal(!showModal)}>
        <FontAwesomeIcon icon={faCircleInfo} color="green" size={18} />
      </Pressable>

      {showModal && (
        <Modal title={title} setShow={setShowModal} style={{ maxWidth: 800 }}>
          <Element />
        </Modal>
      )}
    </>
  );
};

export default Card;
