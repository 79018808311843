import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import FileDownload from 'js-file-download';

import ClientContext from 'app/src/contexts/ClientContext';
import RelayTable from 'app/src/elements/RelayTable';
import { Title, Text } from 'app/src/styles';

import { DATA_SUBMISSIONS } from './queries';

const DataSubmissions = () => {
  const download = submission => {
    FileDownload(submission.data, submission.filename);
  };

  const columns = [
    {
      key: 'filename',
      label: 'Filename',
    },
    {
      key: 'submittedAt',
      label: 'Submitted',
    },
    {
      key: 'data',
      sort: 'prevent',
      content: ({ entry }) => (
        <Pressable onPress={() => download(entry)}>
          <Text>Download</Text>
        </Pressable>
      ),
    },
  ];

  return (
    <View>
      <Title>Data Submissions</Title>

      <RelayTable
        query={DATA_SUBMISSIONS}
        queryName="dataSubmissions"
        columns={columns}
      />
    </View>
  );
};

export default DataSubmissions;
