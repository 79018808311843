import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import Modal from 'app/src/elements/Modal';
import { Text } from 'app/src/styles';

const Container = styled(View)`
  flex-direction: row;
  gap: 20px;
`;

const EditableDetails = props => {
  const { children, title, modalContent, canEdit, icon, ...modalProps } = props;
  const [showModal, setShowModal] = useState(false);

  const ModalContent = modalContent;

  return (
    <Container>
      {canEdit && (
        <View>
          <Pressable onPress={() => setShowModal(!showModal)}>
            <FontAwesomeIcon
              icon={icon || faPenToSquare}
              color="green"
              size={24}
            />
          </Pressable>
        </View>
      )}
      <View style={{ maxWidth: '90%' }}>
        {children}
      </View>
      {showModal && (
        <Modal title={title} setShow={setShowModal}>
          <ModalContent setShow={setShowModal} {...modalProps} />
        </Modal>
      )}
    </Container>
  );
};

export default EditableDetails;
