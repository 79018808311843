import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import Notes from 'app/src/components/Notes/List';
import ClientContext from 'app/src/contexts/ClientContext';
import { useParams } from 'app/src/utils/routing';
import DataState from 'app/src/elements/DataState';
import {
  Text,
  Title,
  TitleLinks,
  TitleWithLinks,
  Subtitle
} from 'app/src/styles';
import { datetimeShort } from 'app/src/utils/formatting';
import { Link } from 'app/src/utils/routing';

import CommissionDetails from './CommissionDetails';
import PrintPreview from './PrintPreview';
import ShopifyItem from './ShopifyItem';
import { ORDER } from './queries';

import { Additional } from './new';

const Order = () => {
  const { currentUser } = useContext(ClientContext);
  const [showPrintPreview, setShowPrintPreview] = useState(false);

  if (!currentUser?.isEmployee) {
    return null;
  }

  const { id } = useParams();
  const { data, refetch, ...dataState } = useQuery(ORDER, {
    variables: { id }
  });

  return (
    <View>
      <DataState data={data} {...dataState}>
        <TitleWithLinks>
          <Title withLinks>
            Order
            {data?.order.filledAt && ` ${datetimeShort(data.order.filledAt)}`}
          </Title>

          <TitleLinks>
            <Link to={`/orders/${id}/tags`}>
              <Text>⤇ View Tag Data</Text>
            </Link>

            <Pressable onPress={() => setShowPrintPreview(true)}>
              <Text>⤇ View Tags Print Preview</Text>
            </Pressable>
          </TitleLinks>
        </TitleWithLinks>

        {data && (
          <View style={{ flexDirection: 'row', gap: 24 }}>
            <View style={{ flex: 1, zIndex: 500 }}>
              <View
                style={{
                  flexDirection: 'row', gap: 10, alignItems: 'baseline'
                }}
              >
                <Subtitle>{data.order.name}</Subtitle>
                <Link to={`/dealers/${data.order.dealer.id}`}>
                  <FontAwesomeIcon icon={faEye} color="green" size={24} />
                </Link>
              </View>

              <Text>{data.order.product.name}</Text>

              {data.order.newLicense && (
                <Text>{data.order.newLicense.licenseString}</Text>
              )}

              {data.order.usedLicense && (
                <Text>{data.order.usedLicense.licenseString}</Text>
              )}

              <Text>
                {data.order.quantity} Tags - {data.order.serialSummary}
              </Text>

              <Text>
                Shopify Order #: {data.order.shopifyOrderNumber}
              </Text>

              <Subtitle bordered>Order Notes</Subtitle>
              <Notes
                notes={data.order.notes}
                order={data.order}
                dealer={data.order.dealer}
                refetch={refetch}
              />

              <Subtitle bordered>Dealer Notes</Subtitle>
              <Notes
                notes={data.order.dealer.notes}
                dealer={data.order.dealer}
                refetch={refetch}
              />
            </View>

            <Additional>
              <CommissionDetails
                usedLicense={data.order.usedLicense}
                asOf={data.order.filledAt}
              />

              {data.order.shopifyItem && (
                <ShopifyItem item={data.order.shopifyItem} />
              )}
            </Additional>
          </View>
        )}
      </DataState>
      {showPrintPreview && (
        <PrintPreview order={data.order} setShow={setShowPrintPreview} />
      )}
    </View>
  );
};

export default Order;
