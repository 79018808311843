import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import ExternalLink from 'app/src/elements/ExternalLink';

import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

const Container = styled(View)`
  margin-top: 120px;
  margin-left: 40px;
  margin-right: 30px;
  background-color: white;
  padding: 24px;
  filter: drop-shadow(0px 4px 30px rgba(0, 100, 0, 0.8));
`;

const OrderText = styled(Text)`
  font-weight: 600;
  font-size: 18;
  text-align: center;
`;

const OrderTags = () => {
  return (
    <Container>
      <ExternalLink
        url="https://autodealersresource.com/collections/temporary-tags"
      >
        <View>
          <OrderText>To order more Oklahoma</OrderText>
          <OrderText>10 Day Temporary Tags,</OrderText>
          <OrderText>click here to visit</OrderText>
          <OrderText>the ADR store.</OrderText>
        </View>
      </ExternalLink>
    </Container>
  );
};

export default OrderTags;
