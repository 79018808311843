import React from 'react';
import { View, ScrollView } from 'react-native';
import { useQuery } from '@apollo/client';
import styled from 'styled-components/native';

import { Loading } from 'app/src/elements/DataState';
import { Text, Subtitle } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { delimited } from 'app/src/utils/formatting';

import { DASHBOARD } from './queries';

const METRIC_SETS = [
  { name: 'orders', label: 'Orders Printed', },
  { name: 'tags', label: 'Tags Printed', },
  { name: 'registrations', label: 'Tags Pre-Registered', },
  { name: 'users', label: 'User Sign-Ups', },
];

const SetContainer = styled(View)`
  flex-direction: row;
  gap: 20px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.header};
  border-bottom-style: solid;
  padding-bottom: 10px;
`;

const Column = styled(View)`
  min-width: 220px;
`;

const TodayCount = styled(Text)`
  fontSize: 40;
  fontWeight: 600;
  color: ${colors.header};
`;

const Times = ({ minTime, maxTime }) => {
  if (!minTime) { return null; }

  return (
    <Text style={{ fontSize: 12 }}>
      {minTime.split('T')[0]} - {maxTime.split('T')[0]}
    </Text>
  );
};

const CountWithAverage = props => {
  const {
    label, count, days, businessDays, compare, percent, percentLabel
  } = props;

  const average = (count / days).toFixed(1);
  const averageColor = (count / days) > compare ? 'maroon' : 'blue';

  const businessDayAverage = (count / businessDays).toFixed(1);
  const businessDayAverageColor = (count / businessDays) > compare ?
    'maroon' : 'blue';

  return (
    <View
      style={{ marginTop: 10, flexDirection: 'row', alignItems: 'baseline' }}
    >
      <Text style={{ width: 150 }}>{label}:</Text>
      <Text style={{ width: 100, fontWeight: 600 }}>{delimited(count)}</Text>

      <Text style={{ fontSize: 12, fontWeight: 800 }}>AVG{'  '}</Text>
      <Text
        style={{ fontWeight: 600, width: 100, color: averageColor }}
      >
        {average}
      </Text>

      <Text style={{ fontSize: 12, fontWeight: 800 }}>WKDAY AVG{'  '}</Text>
      <Text
        style={{ fontWeight: 600, width: 100, color: businessDayAverageColor }}
      >
        {businessDayAverage}
      </Text>

      {percentLabel && (
        <>
          <Text style={{ fontWeight: 600 }}>{(percent || 0).toFixed(1)}%</Text>
          {'Current Month' === label && (
            <Text>{' '}{percentLabel}</Text>
          )}
        </>
      )}
    </View>
  );
};

const MetricSet = ({ label, data }) => {
  const todayData = data[0];
  const mtdData = data[1];
  const priorDaysData = data.slice(2);

  return (
    <SetContainer>
      <Column>
        <Subtitle>{label}</Subtitle>

        <TodayCount>{todayData.count}</TodayCount>
        <Times {...todayData} />
      </Column>

      <Column>
        <CountWithAverage {...mtdData} compare={todayData.count} />
        <Times {...mtdData} />

        {priorDaysData.map(priorDayData => (
          <View key={priorDayData.days}>
            <CountWithAverage {...priorDayData} compare={todayData.count} />
            <Times {...priorDayData} />
          </View>
        ))}
      </Column>
    </SetContainer>
  );
};

const Dashboard = () => {
  const { data } = useQuery(DASHBOARD, { fetchPolicy: 'no-cache' });

  if (!data) { return <Loading />; }

  return (
    <ScrollView horizontal>
      <View>
        {METRIC_SETS.map(metricSet => (
          <MetricSet
            key={metricSet.name}
            label={metricSet.label}
            data={data.dashboard[metricSet.name]}
          />
        ))}

        <Subtitle>Contacts</Subtitle>
        <View style={{ flexDirection: 'row', gap: 10 }}>
          <Text>Service Oklahoma Dealer Support:</Text>
          <Text>(405) 521-3535</Text>
          <Text>DealerSupport@service.ok.gov </Text>
        </View>

        <View style={{ flexDirection: 'row', gap: 10 }}>
          <Text>Brennan Clark - SOK Tech Contact</Text>
          <Text style={{ fontWeight: 'bold' }}>Do not give to dealer</Text>
          <Text>(405) 522-0887</Text>
          <Text>brennan.clark@service.ok.gov</Text>
        </View>
      </View>
    </ScrollView>
  );
};

export default Dashboard;
