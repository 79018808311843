const formatAuthErrors = errors => {
  const messages = errors?.map(({ message, extensions }) => {
    if (extensions?.detailed_errors) {
      return message + '. ' + extensions.detailed_errors.join('. ')
    } else {
      return message;
    }
  });

  return messages.join(' ');
};

export default formatAuthErrors;
