import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import { StyledTextInput } from 'app/src/elements/inputs';
import { Picker } from 'app/src/elements/Picker';
import { Text } from 'app/src/styles';
import { useNavigate } from 'app/src/utils/routing';

import { CONNECT_REGISTRATION_CODE } from './queries';

const Container = styled(View)`
  ${props => props.isMobile ? '' : `
    flex-direction: row;
    align-items: flex-end;
  `}
  gap: 10px;
  border-width: 1px;
  margin-bottom: 20px;
  padding: 10px;
  max-width: 1300px;
`;

const RegisterCode = () => {
  const { isMobile, refetchCurrentUser } = useContext(ClientContext);
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();

  const [connectRegistrationCode] = useMutation(CONNECT_REGISTRATION_CODE, {
    onCompleted: data => {
      if (data.connectRegistrationCode.error) {
        setErrorMessage(data.connectRegistrationCode.message);
      } else {
        refetchCurrentUser();
        navigate('/dealers');
      }
    }
  });

  const handleSubmit = () => {
    connectRegistrationCode({ variables: { code } });
  };

  return (
    <Container isMobile={isMobile}>
      <Text>
        If you have a 6-character registration code that came with your latest
        temporary tag order from ADR, you can enter it here:
      </Text>

      <StyledTextInput value={code} onChangeText={setCode} />

      <Button
        label="Submit"
        disabled={6 !== code.length}
        onPress={handleSubmit}
        style={{ margin: 0, padding: 3 }}
      />

      {errorMessage && (
        <Text style={{ fontWeight: 600, color: 'red' }}>{errorMessage}</Text>
      )}
    </Container>
  );
};

export default RegisterCode;
