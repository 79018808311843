import React, { useState } from 'react';
import { View } from 'react-native';
import FileDownload from 'js-file-download';

import { rest } from 'app/src/utils/rest';

export const downloadReport = props => {
  const { path, filename, credentials, params, type, setDownloading } = props;
  setDownloading(type);

  const options = {
    credentials,
    params,
    path: `report-builder/${path}.xlsx`,
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
  };

  const timestamp = new Date().getTime();
  let fullFilename = `${filename || path}-${timestamp}.xlsx`;

  if ('pdf' === type) {
    options.path = `report-builder/${path}.pdf`;
    options.headers.Accept = 'application/pdf';
    fullFilename = `${filename || path}-${timestamp}.pdf`;
  };

  rest(options).then(response => {
    response.blob().then(blob => FileDownload(blob, fullFilename));
    setDownloading(false);
  });
};
