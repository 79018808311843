import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { hoursSinceFilled } from 'app/src/components/Orders/utils';
import { Button } from 'app/src/elements/buttons';
import { Fields } from 'app/src/elements/forms';
import EditableDetails from 'app/src/elements/EditableDetails';
import { Text, Warning, WarningText } from 'app/src/styles';

import EditDealer from 'app/src/components/Dealers/edit';
import Licenses from 'app/src/components/Dealers/Licenses';

import DealerDetails from './DealerDetails';

const DetailFields = props => {
  const {
    dealer, handleSubmit, orderFields, control, errors, setValue, getDealer,
    isEdit
  } = props;

  const hoursSinceLastFill = dealer ? hoursSinceFilled(dealer.orders) : null;
  const recentFillWarning = null !== hoursSinceLastFill &&
    hoursSinceLastFill < (24 * 4);

  return (
    <>
      {recentFillWarning && !isEdit && (
        <Warning>
          <WarningText>
            🚩 Warning: An order was printed for this dealer{' '}
            {hoursSinceLastFill} hours ago.
          </WarningText>
        </Warning>
      )}

      <EditableDetails
        modalContent={EditDealer}
        title="Update Dealer"
        id={dealer.id}
        canEdit
        refetch={() => getDealer({ variables: { id: dealer.id } })}
      >
        <DealerDetails dealer={dealer} />
      </EditableDetails>

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1}}>
          <Fields
            fields={orderFields}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </View>

        <View
          style={{
            justifyContent: 'flex-end', marginLeft: 20, marginBottom: 90
          }}
        >
          <EditableDetails
            modalContent={Licenses}
            title="Dealer Licenses"
            id={dealer.id}
            canEdit
            refetch={() => getDealer({ variables: { id: dealer.id } })}
          />
        </View>
      </View>

      <Button
        label={isEdit ? 'Update Order' : 'Create and Print'}
        onPress={handleSubmit}
        wide
      />
    </>
  );
};

export default DetailFields;
