import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { DELETE_SENDGRID_UNSUBSCRIBE } from 'app/src/components/Users/queries';
import { Button } from 'app/src/elements/buttons';
import Modal from 'app/src/elements/Modal';
import { Text, Warning, WarningText } from 'app/src/styles';

import { ActionLink, ActionLabel } from './ActionLink';
import { EMAIL_STATUS } from './queries';

const EmailHelpModal = ({ email, setShow }) => {
  const [deleteStatus, setDeleteStatus] = useState();

  const { data } = useQuery(EMAIL_STATUS, { variables: { email } });

  const [deleteSendgridUnsubscribe] = useMutation(DELETE_SENDGRID_UNSUBSCRIBE, {
    variables: { email, sendCode: true },
    onCompleted: () => setDeleteStatus('Resubscribe Request Sent'),
  });

  const handleDeleteSendgridUnsubscribe = () => {
    setDeleteStatus('Resubscribing...');
    deleteSendgridUnsubscribe();
  };

  let warning = null;

  if (data?.emailStatus?.unsubscribedAt) {
    warning = (
      <Warning style={{ display: 'flex', flexDirection: 'column' }}>
        <WarningText style={{ fontSize: 16, fontWeight: 600 }}>
          Your address is currently unsubscribed from receiving our emails.
        </WarningText>

        <Button
          label="Click to Resubscribe"
          onPress={handleDeleteSendgridUnsubscribe}
        />
      </Warning>
    );
  } else if (data?.emailStatus?.todayMessageNotDelivered) {
    warning = (
      <Warning>
        <WarningText style={{ fontSize: 16, fontWeight: 600 }}>
          At least one email sent to your address in the past 24 hours was
          blocked. You may need to contact your IT department.
        </WarningText>
      </Warning>
    );
  }

  return (
    <Modal title="Not Receiving Emails" setShow={setShow}>
      <Text>
        Account emails will come from service@oklahomatemptag.com
      </Text>
      <Text>
        Please check your spam folder and whitelist emails from
        service@oklahomatemptag.com
      </Text>

      {deleteStatus ? (
        <Text style={{ marginTop: 10 }}>{deleteStatus}</Text>
      ) : (
        <Text>{warning}</Text>
      )}
    </Modal>
  );
};

const EmailHelp = ({ email }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ActionLink style={{ marginTop: 6 }} onPress={() => setShowModal(true)}>
        <ActionLabel>Not receiving emails?</ActionLabel>
      </ActionLink>

      {showModal && (
        <EmailHelpModal email={email} setShow={setShowModal} />
      )}
    </>
  );
};

export default EmailHelp;
