import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';

import ClientContext from 'app/src/contexts/ClientContext';
import { Title } from 'app/src/styles';
import { Notice } from 'app/src/elements/Notice';

import formatAuthErrors from './formatAuthErrors';
import { ActionLink, ActionLabel } from './ActionLink';
import PasswordForm from './PasswordForm';
import { CONFIRM_USER } from './queries';

const SetPassword = ({ token }) => {
  const [formStatus, setFormStatus] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { updateCredentials } = useContext(ClientContext);

  const [confirmUser] = useMutation(CONFIRM_USER, {
    onCompleted: data => {
      updateCredentials(data.confirmUser.credentials);
    },
    onError: error => {
      setErrorMessage(formatAuthErrors(error.graphQLErrors));
      setFormStatus(null);
    }
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    confirmUser({ variables: { ...input, invitationToken: token } });
  };

  return (
    <View>
      <Title>Set Your Password</Title>
      {errorMessage && <Notice type="error">{errorMessage}</Notice>}
      <PasswordForm onSubmit={onSubmit} formStatus={formStatus} />

      <ActionLink onPress={() => window.location.href = '/'}>
        <ActionLabel>Return to Login</ActionLabel>
      </ActionLink>
    </View>
  );
};

export default SetPassword;
