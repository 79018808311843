import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Text } from 'app/src/styles';
import { Link, Outlet } from 'app/src/utils/routing';

const Menu = styled(View)`
  width: 200px;
  gap: 20px;
`;

const Category = styled(View)`
`;

const CategoryLabel = ({ to, children }) => {
  return (
    <Link to={to}>
      <Text>{children}</Text>
    </Link>
  );
};

const Item = ({ to, children }) => {
  return (
    <Link to={to} style={{ marginLeft: 10 }}>
      <Text>{children}</Text>
    </Link>
  );
};

const Help = ({ children }) => {
  return (
    <View style={{ flexDirection: 'row' }}>
      <Menu>
        <Category>
          <CategoryLabel to="/how-to/account">Account</CategoryLabel>
          <Item to="/how-to/account">Create Account</Item>
        </Category>

        <Category>
          <CategoryLabel to="/how-to/users">Users</CategoryLabel>
        </Category>

        <Category>
          <CategoryLabel to="/how-to/pre-registration">
            Pre-Registration
          </CategoryLabel>
        </Category>
      </Menu>

      <View style={{ flex: 1 }}>
        <Outlet />
      </View>
    </View>
  );
};

export default Help;
