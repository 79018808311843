import React from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';

import { Button } from 'app/src/elements/buttons';
import Modal from 'app/src/elements/Modal';
import { Subtitle, Text } from 'app/src/styles';

import { CLEAR_TAG_DATA } from './queries';

const ClearForm = ({ tag, setShow, onCompleted }) => {
  const [clearTagData] = useMutation(CLEAR_TAG_DATA, { onCompleted });

  const handleSubmit = input => {
    clearTagData({ variables: { id: tag.id } });
  };

  return (
    <Modal title="Clear Tag Data" setShow={setShow}>
      <Text>
        Clicking the button below will clear pre-registration data associated
        with this tag.
      </Text>
      <Text>
        Use this if you did not actually fill out anything on
        the physical temporary tag and want to use it for a different customer.
      </Text>

      <Button label="Clear Tag Data" onPress={handleSubmit} wide />
      <Button
        label="Cancel"
        onPress={() => setShow(false)}
        type="Cancel"
        wide
      />
    </Modal>
  );
};

export default ClearForm;
