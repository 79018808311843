import { Pressable } from 'react-native';
import styled from 'styled-components/native';

import { Text } from 'app/src/styles';

export const ActionLink = styled(Pressable)`
  margin-top: 20px;
`;

export const ActionLabel = styled(Text)`
  text-align: right;
`;
