import React, { useState, useContext } from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';
import { ApolloProvider } from '@apollo/client';
import styled from 'styled-components/native';
import ClientContext from 'app/src/contexts/ClientContext';
import { textGray } from 'app/src/styles/colors';

import Header from 'app/src/components/Header';
import Footer from 'app/src/components/Footer';
import DocRoutes from 'app/src/components/Docs/Routes';
import config from 'app/src/config';
import DataState, { Loading } from 'app/src/elements/DataState';
import Video from 'app/src/elements/Video';
import { Subtitle } from 'app/src/styles';
import { Router } from 'app/src/utils/routing';

import LoginToken from './LoginToken';
import RegisterLogin from './new';
import SetPassword from './SetPassword';
import ResetPassword from './ResetPassword';
import AuthMenu from './Menu';

const Screen = styled(SafeAreaView)`
  min-height: 100vh;
`;

const ContentContainer = styled(View)`
  flex-direction: ${props => props.isMobile ? 'column-reverse' : 'row'};
  flex: 1;
  align-self: center;
  gap: 60px;
  padding: 20px;
`;

const Content = styled(ScrollView)`
  width: 400px;
  padding: 20px;
`;

const Auth = () => {
  const {
    apolloClientForAuth, isLoggedIn, storageChecked, isMobile
  } = useContext(ClientContext);

  const params = new URLSearchParams(window.location.search);
  const loginToken = params.get('loginToken');
  const inviteToken = params.get('inviteToken');
  const resetToken = params.get('resetToken') ||
    params.get('reset_password_token');
  const registrationCode = params.get('code');

  if (window.location.pathname.startsWith('/how-to')) {
    return (
      <Router>
        <Screen>
          <Header />
          <AuthMenu />

          <View style={{ margin: 20 }}>
            <DocRoutes prefix="/how-to/" />
          </View>
        </Screen>
      </Router>
    );
  }

  let content = <Loading />;

  if (registrationCode) {
    content = <RegisterLogin registrationCode={registrationCode} />
  } else if (loginToken) {
    content = <LoginToken token={loginToken} />;
  } else if (inviteToken) {
    content = <SetPassword token={inviteToken} />;
  } else if (resetToken) {
    content = <ResetPassword token={resetToken} />;
  } else if (storageChecked) {
    content = <RegisterLogin />;
  };

  return (
    <ApolloProvider client={apolloClientForAuth}>
      <Screen>
        <Header />
        <AuthMenu />

        <ContentContainer isMobile={isMobile}>
          <Content>
            {content}
          </Content>
        </ContentContainer>

        <Footer />
      </Screen>
    </ApolloProvider>
  );
};

export default Auth;
