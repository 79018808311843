import React, { useContext, useRef } from 'react';
import { Pressable, View } from 'react-native';
import { useQuery } from '@apollo/client';

import ClientContext from 'app/src/contexts/ClientContext';
import DataState from 'app/src/elements/DataState';
import { Title, Text } from 'app/src/styles';
import { rest } from 'app/src/utils/rest';

import { IMAGE_REPEAT_OPTIONS } from './queries';

const ImageRepeat = () => {
  const { credentials } = useContext(ClientContext);
  const iframeRef = useRef();

  const { data, ...dataState } = useQuery(IMAGE_REPEAT_OPTIONS);

  const setupIframe = blob => {
    const dataUrl = window.URL.createObjectURL(blob);
    iframeRef.current.setAttribute('src', dataUrl);
  };

  const preview = ({ path, type }) => {
    let getPath = `pdf/image-repeat.pdf?path=${path}&type=${type}`;

    rest({
      path: getPath,
      options: { headers: { Accept: 'application/pdf' } },
      credentials,
    }).then(response => {
      response.blob().then(blob => setupIframe(blob));
    });
  };

  return (
    <View style={{ height: '80vh' }}>
      <Title>Image Repeat</Title>

      <DataState data={data} {...dataState}>
        {data?.imageRepeatOptions.map(option => (
          <Pressable key={option.path} onPress={() => preview(option)}>
            <Text>{option.path} - {option.type}</Text>
          </Pressable>
        ))}
      </DataState>

      <iframe ref={iframeRef} width="100%" height="100%"></iframe>
    </View>
  );
};

export default ImageRepeat;
