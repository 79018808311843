import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'app/src/elements/buttons';
import DataState from 'app/src/elements/DataState';
import {
  FieldLabel,
  StyledTextInput,
  StyledPicker,
} from 'app/src/elements/inputs';
import { Picker } from 'app/src/elements/Picker';
import { Text } from 'app/src/styles';

import { LICENSE_TYPES } from './LicenseInput';
import { DEALER, CREATE_LICENSE, INACTIVATE_LICENSE } from './queries';

const AddLicense = styled(View)`
  margin-top: 20px;
  padding-top: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: black;
`;

const Licenses = ({ id, setShow, refetch }) => {
  const [licenseType, setLicenseType] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [createErrors, setCreateErrors] = useState();

  const { data, ...dataState } =
    useQuery(DEALER, { variables: { id } });

  const [createLicense] = useMutation(CREATE_LICENSE, {
    onCompleted: data => {
      if (data.createLicense.errors?.length) {
        setCreateErrors(data.createLicense.errors);
      } else {
        refetch();
        dataState.refetch();
        setLicenseType('');
        setLicenseNumber('');
      }
    }
  });

  const [inactivateLicense] = useMutation(INACTIVATE_LICENSE, {
    onCompleted: ()=> {
      refetch();
      dataState.refetch();
    }
  });

  const handleSubmit = () => {
    createLicense({
      variables: { dealerId: id, licenseType, licenseNumber }
    });
  };

  const handleInactivate = license => {
    const confirmation =
      `Really inactivate ${license.licenseType} ${license.licenseNumber}`;

    if (confirm(confirmation)) {
      inactivateLicense({ variables: { id: license.id } });
    }
  }

  const activeLicenses = data ?
    data?.dealer.licenses.filter(license => !license.inactiveAt) : [];

  const createEnabled = licenseType && licenseNumber;

  return (
    <DataState data={data} {...dataState}>
      {activeLicenses.map(license => (
        <View
          key={license.id}
          style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}
        >
          <Text>{license.licenseType} {license.licenseNumber}</Text>
          <Pressable onPress={() => handleInactivate(license)}>
            <FontAwesomeIcon icon={faX} color="red" />
          </Pressable>
        </View>
      ))}

      <AddLicense>
        <Text>Add License:</Text>

        {createErrors && (
          <Text>{createErrors[0].message}</Text>
        )}

        <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
          <StyledPicker
            selectedValue={licenseType}
            onValueChange={setLicenseType}
          >
            {LICENSE_TYPES.map(type => (
              <Picker.Item key={type.value} {...type} />
            ))}
          </StyledPicker>
          <StyledTextInput
            style={{ height: 32 }}
            value={licenseNumber}
            onChangeText={setLicenseNumber}
          />

          <Button
            style={{ marginLeft: 20 }}
            disabled={!createEnabled}
            label="Save"
            onPress={handleSubmit}
          />
        </View>
      </AddLicense>
    </DataState>
  );
};

export default Licenses;
