import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { View } from 'react-native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Title } from 'app/src/styles';
import { Notice } from 'app/src/elements/Notice';

import formatAuthErrors from './formatAuthErrors';
import { ActionLink, ActionLabel } from './ActionLink';
import PasswordForm from './PasswordForm';
import { RESET_PASSWORD } from './queries';

const ResetPassword = ({ token }) => {
  const [formStatus, setFormStatus] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { updateCredentials } = useContext(ClientContext);

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: data => {
      window.location.href = '/';
    },
    onError: error => {
      setErrorMessage(formatAuthErrors(error.graphQLErrors));
      setFormStatus(null);
    }
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    resetPassword({ variables: { ...input, resetPasswordToken: token } });
  };

  return (
    <View>
      <Title>Reset Your Password</Title>
      {errorMessage && <Notice type="error">{errorMessage}</Notice>}
      <Notice type="warning">
        After resetting your password, you will be redirected to the login
        screen.
      </Notice>
      <PasswordForm onSubmit={onSubmit} formStatus={formStatus} />

      <ActionLink onPress={() => window.location.href = '/'}>
        <ActionLabel>Return to Login</ActionLabel>
      </ActionLink>
    </View>
  );
};

export default ResetPassword;
