import React, { useContext } from 'react';
import { View } from 'react-native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Text } from 'app/src/styles';

import NewDealer from './new';

const PendingDealer = () => {
  const { currentUser, inactiveDealers } = useContext(ClientContext);

  if (inactiveDealers.length > 0) {
    return (
      <View>
        <Text>
          We are currently reviewing your account.
          You will receive an email or can check back here within 24 hours.
        </Text>
      </View>
    );
  }

  return (
    <View>
      <Text style={{ marginBottom: 20 }}>
        Enter your dealership information below. We will review your information
        within 24 hours and notify you via email.
      </Text>

      <NewDealer pending />
    </View>
  );
};

export default PendingDealer;
