import React, { useContext, useEffect, useRef } from 'react';

import ClientContext from 'app/src/contexts/ClientContext';
import Modal from 'app/src/elements/Modal';
import { rest } from 'app/src/utils/rest';

const PrintPreview = ({ order, setShow }) => {
  const { credentials } = useContext(ClientContext);
  const iframeRef = useRef();

  const setupIframe = blob => {
    const dataUrl = window.URL.createObjectURL(blob);
    iframeRef.current.setAttribute('src', dataUrl);
  };

  const preview = () => {
    rest({
      path: `pdf/order/${order.id}.pdf?printer=Sample`,
      options: { headers: { Accept: 'application/pdf' } },
      credentials,
    }).then(response => {
      response.blob().then(blob => setupIframe(blob));
    });
  };

  useEffect(preview, []);

  return (
    <Modal title={order.serialSummary} setShow={setShow}>
      <iframe ref={iframeRef} width="100%" height="600"></iframe>
    </Modal>
  );
};

export default PrintPreview;
