import React, { useContext } from 'react';
import { View } from 'react-native';
import styled from 'styled-components';

import ClientContext from 'app/src/contexts/ClientContext';
import ExternalLink from 'app/src/elements/ExternalLink';
import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

const Notice = styled.div`
  display: flex;
  flex-direction: column;
  border-width: 2px;
  border-color: ${colors.header};
  border-style: solid;
  margin-bottom: 20px;
  padding: 10px;
  gap: 10px;
`;

const DisplayAlerts = () => {
  const { currentUser } = useContext(ClientContext);

  if (!currentUser.alerts?.length) { return null; }

  return (
    <View>
      {currentUser.alerts.map(alert => (
        <Notice key={alert.id}>
          <Text>{alert.body}</Text>

          {alert.url && (
            <ExternalLink url={alert.url}>
              <Text>{alert.url}</Text>
            </ExternalLink>
          )}
        </Notice>
      ))}
    </View>
  );
};

export default DisplayAlerts;
