import { gql } from '@apollo/client';

export const DATA_SUBMISSIONS = gql`
  query DataSubmissions (
    $limit: Int!
    $offset: String!
    $options: ConnectionOptionsInput!
  ) {
    dataSubmissions (
      first: $limit
      after: $offset
      options: $options
    ) {
      edges {
        cursor
        node {
          id
          filename
          submittedAt
          data
        }
      }
      totalCount
    }
  }
`;


