import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import styled from 'styled-components/native';

import Ticket from 'app/src/components/Ticket';
import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import ExternalLink from 'app/src/elements/ExternalLink';

const PRIVACY_URL =
  'https://www.termsfeed.com/live/1fbf4d5b-896b-46e9-b23a-ef16c1003ff4';

const Container = styled(View)`
  background-color: ${colors.header};
  min-height: 90px;
  padding: 20px;
  padding-left: 40px;
`;

const FooterText = styled(Text)`
  color: white;
  font-size: 16px;
  margin-bottom: 6px;
`;

const Links = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const Footer = () => {
  const [showTicket, setShowTicket] = useState(false);

  return (
    <Container>
      <FooterText>
        Managed by Auto Dealers Resource ·{' '}
        813 NW 34th St, Moore, OK 73160 · service@oklahomatemptag.com
      </FooterText>

      <Links>
        <ExternalLink url={PRIVACY_URL}>
          <FooterText>Privacy Policy</FooterText>
        </ExternalLink>
        <Pressable onPress={() => setShowTicket(true)}>
          <FooterText>Provide Feedback</FooterText>
        </Pressable>
      </Links>

      {showTicket && (
        <Ticket setShow={setShowTicket} />
      )}
    </Container>
  );
};

export default Footer;
