import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Text } from 'app/src/styles';

const NoticeContainer = styled(View)`
  border-width: 2px;
  border-style: sold;
  padding: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const Notice = ({ type, children }) => {
  let borderColor = 'green';
  if ('warning' === type) {
    borderColor = 'orange';
  } else if ('error' === type) {
    borderColor = 'red';
  }

  const notices = 'string' === typeof children ? [children] : children;

  return (
    <NoticeContainer style={{borderColor}}>
      {notices.map(notice => (
        <Text key={notice} style={{fontWeight: 600}}>{notice}</Text>
      ))}
    </NoticeContainer>
  );
};

export default Notice;
