import React from 'react';
import { View } from 'react-native';

import { Container, Items, Label } from 'app/src/components/Menu';
import ExternalLink from 'app/src/elements/ExternalLink';
import { Text } from 'app/src/styles';

const AuthMenu = () => {
  return (
    <Container>
      <Items>
        <ExternalLink url="/" target="_self">
          <Label>Login or Register</Label>
        </ExternalLink>
        <ExternalLink url="/how-to" target="_self">
          <Label>How To & FAQs</Label>
        </ExternalLink>
      </Items>
    </Container>
  );
};

export default AuthMenu;
