import React from 'react';
import { View, Pressable } from 'react-native';
import { Controller } from 'react-hook-form';
import styled from 'styled-components/native';
import { DateTime } from 'luxon';

import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

import { FieldLabel } from './inputs';

const ProductContainer = styled(Pressable)`
  flex-direction: row;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.hasMatch ? 'black' : 'gray'};
  border-radius: 6px;
  padding: 10px;
  background-color: ${props => props.hasMatch ? 'white' : 'lightgray'};
`;

const Check = styled(Text)`
  color: ${colors.header};
  font-size: 50px;
  margin-right: 10px;
`;

const productOrders = ({ dealer, product }) => (
  dealer.orders.filter(order => order.product.id === product.id)
);

const Product = ({ product, dealer, field, setValue }) => {
  let match = dealer.dealerProducts.find(dealerProduct => (
    dealerProduct.product.id === product.id &&
    productOrders({ dealer, product: dealerProduct.product }).length > 0
  ));
  let isSuggested = false;

  if (!match) {
    match = dealer.dealerProducts.find(dealerProduct => (
      dealerProduct.suggestedProduct?.id === product.id &&
      productOrders({ dealer, product: dealerProduct.product }).length > 0
    ));

    isSuggested = true;
  }

  const hasMatch = !!match;
  const orders = hasMatch ?
    productOrders({ dealer, product: match.product }) : [];
  const lastOrder = hasMatch ? orders[orders.length - 1] : null;
  const lastOrderAt = lastOrder ? DateTime.fromISO(lastOrder.filledAt) : null;

  const handlePress = () => {
    if (field.value === product.id) {
      field.onChange(null);
    } else {
      field.onChange(product.id);

      if (match) {
        if (!isSuggested) {
          setValue('quantity', lastOrder?.quantity);
        }

        setValue('newLicenseId', match.newLicense?.id);
        setValue('usedLicenseId', match.usedLicense?.id);
      }
    }
  };

  return (
    <ProductContainer hasMatch={hasMatch} onPress={handlePress}>
      {product.id === field.value && (
        <Check>✓</Check>
      )}
      <View>
        <Text>{product.name}</Text>
        {hasMatch ? (
          <>
            <Text>
              Last Order:
            </Text>
            <Text>
              {lastOrderAt?.toLocaleString(DateTime.DATETIME_SHORT)}
              {isSuggested && ' (Old Style)'}
            </Text>
            {match.newLicense && !match.newLicense.inactiveAt && (
              <Text>{match.newLicense.licenseString}</Text>
            )}
            {match.usedLicense && !match.usedLicense.inactiveAt && (
              <Text>{match.usedLicense.licenseString}</Text>
            )}
          </>
        ) : (
          <Text>No prior orders</Text>
        )}
      </View>
    </ProductContainer>
  );
};

const ProductSelect = props => {
  const {
    name, label, hint, errors, control, rules, products, dealer, setValue
  } = props;

  if (!dealer || !products) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <View style={{ marginBottom: 10 }}>
          <FieldLabel
            name={name}
            label={label}
            hint={hint}
            errors={errors}
          />
          <View style={{ flexDirection: 'row', gap: 10 }}>
            {products.map(product => (
              <Product
                key={product.id}
                product={product}
                dealer={dealer}
                field={field}
                setValue={setValue}
              />
            ))}
          </View>
        </View>
      )}
    />
  );
};

export default ProductSelect;
