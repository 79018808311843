import React from 'react';
import { View, Pressable } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

import NewNote from 'app/src/components/Notes/new';
import EditableDetails from 'app/src/elements/EditableDetails';
import { Text } from 'app/src/styles';
import { datetimeShort } from 'app/src/utils/formatting';

const Notes = ({ notes, dealer, order, user, refetch }) => {
  return (
    <EditableDetails
      modalContent={NewNote}
      title="Add Note"
      dealer={dealer}
      order={order}
      user={user}
      icon={faCirclePlus}
      canEdit
      refetch={refetch}
    >
      {notes.toReversed().map(note => (
        <View key={note.id} style={{ marginBottom: 10 }}>
          <Text style={{ fontWeight: 600 }}>
            {note.name}
            {' - '}
            {datetimeShort(note.createdAt)}

            {note.order && (
              ` - Order Printed ${datetimeShort(note.order.filledAt)}`
            )}
          </Text>

          <Text>{note.body}</Text>
        </View>
      ))}
    </EditableDetails>
  );
};

export default Notes;
