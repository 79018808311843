import { useContext } from 'react';
import fileDownload from 'js-file-download';

import config from 'app/src/config';
import ClientContext from 'app/src/contexts/ClientContext';

export const rest = props => {
  let { path, url, credentials, skipDefaultHeaders, ...options } = props;
  options = options || {};

  if (path && !url) {
    url = `${config.api.baseUri}/${path}`;
  }

  url = new URL(url);

  if (options.params) {
    const params = { ...options.params };

    Object.keys(params).forEach(key => {
      if (undefined === params[key] || null === params[key]) {
        delete params[key];
      }
    });

    url.search = new URLSearchParams(params).toString();
  }

  if (!options.method) { options.method = 'get' };

  if (!skipDefaultHeaders) {
    options.headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      'access-token': credentials.accessToken,
      ...credentials,
      ...options.headers
    };
  }

  return fetch(url, options);
};

export const useRest = () => {
  const { credentials } = useContext(ClientContext);

  const download = ({ path, filename, options }) => {
    const timestamp =
      new Date().toISOString().replace(/[-T:]/g, '').split('.')[0]

    const filenameWithTimestamp = filename.split('.')[0] +
      `-${timestamp}.${filename.split('.')[1]}`;

    rest({ path, options, credentials }).then(response => {
      response.blob().then(blob => fileDownload(blob, filenameWithTimestamp));
    });
  };

  return { download };
};
