import React from 'react';
import { View } from 'react-native';
import { Subtitle, Text } from 'app/src/styles';

import Video from 'app/src/elements/Video';

const Users = () => {
  return (
    <View>
      <Subtitle>Managing Users</Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          If you are a dealer administrator, you can manage other users access
          to the dealer account.
        </Text>

        <Text>
          To start, go to Account -> Users. You will see a list of users with
          access to any dealers to which you also have access.
        </Text>

        <Text>
          If you are an Administrator of one or more dealers, you can add users.
          Click the "Add User" link about the list. Enter the users name and
          email and choose which dealers they have access to. You can also make
          this dealer an Administrator.
        </Text>

        <Text>
          If the user already has an account with oklahomatemptag.com, they
          will be able able to access this dealer with their existing
          credentials. Otherwise, they will recieve an invitation email.
        </Text>

        <Text style={{ fontWeight: 'bold' }}>
          Changing or Removing Access
        </Text>

        <Text>
          You can also edit a users access to dealers you administer. To do
          so, click the "Edit" link at the far right of the table. You can
          change their access level.
        </Text>

        <Text>
          To completely remove access for the user for all dealerships you
          administer, click the "Remove All Access to Dealers I administer"
          text bordered in red, then click save.
        </Text>

        <Text>
          See this video for more information.
        </Text>
      </View>

      <Video
        url="https://www.youtube.com/embed/gGwIQN9QE6Y?si=-NDjOTh_oa0jPQkS"
        title="oklahomatemptag.com user administration"
      />
    </View>
  );
};

export default Users;
