import { DateTime } from 'luxon';

export const hoursSinceFilled = orders => {
  if (!orders) { return null; }

  let order = orders;

  if (Array.isArray(orders)) {
    order = orders
      .filter(entry => entry.filledAt)
      .toSorted((entry, other) => entry.filledAt < other.filledAt ? 1 : -1)[0];
  }

  if (!order) { return null; }

  return Math.floor(
    DateTime.now().diff(DateTime.fromISO(order.filledAt), 'hours').hours
  );
};

