import React from 'react';
import { View } from 'react-native';
import { Subtitle, Text } from 'app/src/styles';

import Video from 'app/src/elements/Video';

const PreRegisterSale = () => {
  return (
    <View>
      <Subtitle>Pre-Registering Sales</Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          You can use oklahomatemptag.com to pre-register vehicle sales in
          accordance with SB2035.
        </Text>

        <Text>
          See this video for more information.
        </Text>
      </View>

      <Video
        url="https://www.youtube.com/embed/_ARoo5G9-X0?si=IWYYZca8uGPNdQrq"
        title="oklahomatemptag.com pre-register sale"
      />
    </View>
  );
};

export default PreRegisterSale;
