import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { Button } from 'app/src/elements/buttons';
import { StyledTextInput } from 'app/src/elements/inputs';
import Modal from 'app/src/elements/Modal';
import { Subtitle, Text } from 'app/src/styles';

import { UPDATE_SERIAL_NUMBER } from './queries';

const EditSerialNumber = ({ tag, setShow, onCompleted }) => {
  const [serialNumber, setSerialNumber] = useState(tag.serialNumber);
  const [formStatus, setFormStatus] = useState();

  const [updateSerialNumber] = useMutation(UPDATE_SERIAL_NUMBER, {
    onCompleted: data => onCompleted(data.updateSerialNumber.tag)
  });

  const handleSubmit = () => {
    setFormStatus('Submitting...');
    updateSerialNumber({ variables: { id: tag.id, serialNumber } });
  };

  return (
    <Modal title="Edit Tag Number" setShow={setShow}>
      <StyledTextInput
        value={serialNumber}
        onChangeText={setSerialNumber}
      />

      {formStatus ? (
        <Text>{formStatus}</Text>
      ) : (
        <Button label="Update" onPress={handleSubmit} wide />
      )}
    </Modal>
  );
};

export default EditSerialNumber;
