import React, { useContext, useEffect, useState } from 'react';
import { View, TextInput, Pressable } from 'react-native';
import { useLazyQuery } from '@apollo/client';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

import Result from './Result';

export const Container = styled(View)`
  flex-direction: row;
  background-color: white;
  height: 32px;
  padding-left: 6px;
  padding-right: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
`;

export const Input = styled(TextInput)`
  outline-width: 0px;
  outline-color: white;
  flex: 1;
`;

export const Results = styled(View)`
  position: absolute;
  top: 30px;
  left: 10px;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding-top: 5px;
  max-height: 25vw;
  overflow-y: auto;
`;

const Autocomplete = props => {
  const {
    selectedLabel, setSelected, query, queryName, variables, clientSelect,
    matchResultAttribute,
  } = props;

  const client = useContext(ClientContext);
  const [term, setTerm] = useState(selectedLabel || '');
  const [results, setResults] = useState();

  const [getResults] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted: data => setResults(data[queryName])
  });

  useEffect(() => {
    if (term && term.length > 1) {
      const matchResult = matchResultAttribute ?
        results?.find(result => (
          result[matchResultAttribute] === term.toUpperCase()
        )
      ) : null;

      if (matchResult) {
        setSelected(matchResult);
      } else if (clientSelect) {
        const matchGroups = {
          exact: [],
          start: [],
          end: [],
          any: [],
        };

        client[clientSelect].forEach(entry => {
          const entryLabel = entry.label.toLowerCase();
          const termMatch = term.toLowerCase();

          if (entryLabel === termMatch) {
            matchGroups.exact.push(entry);
          } else if (entryLabel.startsWith(termMatch)) {
            matchGroups.start.push(entry);
          } else if (entryLabel.endsWith(termMatch)) {
            matchGroups.end.push(entry);
          } else if (entryLabel.includes(termMatch)) {
            matchGroups.any.push(entry);
          }
        });

        setResults([
          ...matchGroups.exact,
          ...matchGroups.start,
          ...matchGroups.end,
          ...matchGroups.any,
        ]);
      } else {
        if (selectedLabel && selectedLabel !== term) { setSelected(null); }
        getResults({ variables: { ...variables, term }});
      }
    }
  }, [term]);

  useEffect(() => {
    if (typeof(selectedLabel) !== 'undefined' && term !== selectedLabel) {
      setTerm(selectedLabel);
    }
  }, [selectedLabel]);

  const showResults = results && term !== selectedLabel &&
    term.length > 1 && results.length > 0;

  return (
    <Container>
      <Input
        placeholder="Search"
        placeholderTextColor="lightgray"
        value={term}
        onChange={e => setTerm(e.target.value)}
      />
      {term.length > 0 && (
        <Pressable onPress={() => { setTerm(''); setSelected(null); }}>
          <Text>X</Text>
        </Pressable>
      )}
      {showResults && (
        <Results>
          {results.map(result => (
            <Result
              key={`${result.__typename}-${result.id || result.value}`}
              result={result}
              setSelected={setSelected}
            />
          ))}
        </Results>
      )}
    </Container>
  );
};

export default Autocomplete;
