import React from 'react';
import { View } from 'react-native'
import styled from 'styled-components/native';

import { Text } from 'app/src/styles';
import { cityStateZip } from 'app/src/utils/address';

const Container = styled(View)`
  padding-bottom: 4px;
  margin-bottom: 10px;
`;

const DealerDetails = ({ dealer }) => {
  return (
    <Container>
      <Text>{dealer.street}</Text>
      <Text>{cityStateZip(dealer)}</Text>
      <Text>{dealer.phone}</Text>
      <Text>{dealer.url}</Text>
    </Container>
  );
};

export default DealerDetails;
