import React from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';

import { Button } from 'app/src/elements/buttons';
import { Subtitle, Text } from 'app/src/styles';

import { UNVOID_TAG } from './queries';

const UnvoidForm = ({ tag, onVoidChange }) => {
  const [unvoidTag] = useMutation(UNVOID_TAG, { onCompleted: onVoidChange });

  const onSubmit = () => {
    unvoidTag({ variables: { id: tag.id } });
  };

  return (
    <View>
      <Subtitle>Remove Void</Subtitle>
      <Text>
        Sale has been marked void, but void has not been submitted to Service OK
      </Text>

      <Button label="Remove Void" onPress={onSubmit} wide />
    </View>
  );
};

export default UnvoidForm;
