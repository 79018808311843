import React, { useContext, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import { Text, Warning } from 'app/src/styles';

import {
  DEALER_FEATURE_SUBSCRIPTIONS,
  SETUP_FEATURE_SUBSCRIPTION,
} from './queries';

const NewFeatureSubscription = ({ dealerId }) => {
  const { currentUser } = useContext(ClientContext);
  const [formStatus, setFormStatus] = useState();

  const { data } = useQuery(DEALER_FEATURE_SUBSCRIPTIONS, {
    variables: { id: dealerId }
  });

  const [setupFeatureSubscription] = useMutation(SETUP_FEATURE_SUBSCRIPTION, {
    onCompleted: data => {
      window.location.href = data.setupFeatureSubscription.sessionUrl;
    }
  });

  const handleSubscribe = () => {
    setFormStatus('Processing');
    setupFeatureSubscription({ variables: { dealerId } });
  };

  if (!data) { return null; }

  const activeFeatureSubscriptions = data.dealer.featureSubscriptions
    .filter(subscription => !subscription.deletedAt);

  if (activeFeatureSubscriptions.length > 0) { return null; }
  if (!currentUser) { return null; }

  const subscriptionRequiredForNonAdrOn =
    DateTime.fromISO(currentUser.subscriptionRequiredForNonAdrOn);

  let notice =
    'To pre-register sales using temporary tags not purchased from ADR, ' +
    'a subscription is required.';

  if (subscriptionRequiredForNonAdrOn > DateTime.now()) {
    notice =
      'Beginning ' +
      subscriptionRequiredForNonAdrOn.toLocaleString(DateTime.DATE_HUGE) +
      ' a subcription will be required to pre-register temporary tags ' +
      'that were not purchased from ADR.';
  }

  return (
    <Warning>
      <Text>{notice}</Text>
      <Text>
        The price is $49.00 for 3 months and will auto-renew until canceled.
      </Text>

      <Button
        label="Click to Subscribe"
        onPress={handleSubscribe}
        processing={'Processing' === formStatus}
      />
    </Warning>
  );
};

export default NewFeatureSubscription;
