import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import { StyledTextInput } from 'app/src/elements/inputs';
import { Notice } from 'app/src/elements/Notice';
import { Title, Text } from 'app/src/styles';

import { UPDATE_USER_OTP_TYPE } from 'app/src/components/Users/queries';

import { SETUP_TOTP, VERIFY_TOTP, DISABLE_TOTP } from './queries';

const Result = styled(View)`
  align-items: center;
  margin-top: 40px;
  gap: 40px;
`;

const SetupTOTP = () => {
  const { currentUser, refetchCurrentUser } = useContext(ClientContext);
  const [svg, setSvg] = useState();
  const [currentTry, setCurrentTry] = useState(0);
  const [formStatus, setFormStatus] = useState();
  const [code, setCode] = useState('');

  const [setupTOTP] = useMutation(SETUP_TOTP, {
    onCompleted: data => setSvg(data.setupTotp.base64)
  });

  const [verifyTotp] = useMutation(VERIFY_TOTP, {
    onCompleted: data => {
      if (data.verifyTotp.success) {
        setCurrentTry(currentTry + 1);
        setCode('');
        setFormStatus('success');
        refetchCurrentUser();
      } else {
        setFormStatus('invalid');
      }
    }
  });

  const [disableTotp] = useMutation(DISABLE_TOTP, {
    onCompleted: data => {
      if (data.disableTotp.success) {
        setCurrentTry(0);
        refetchCurrentUser();
        setFormStatus('disable-success');
      } else {
        setFormStatus('disable-failed');
      }
    }
  });

  const [updateUserOtpType] = useMutation(UPDATE_USER_OTP_TYPE, {
    onCompleted: refetchCurrentUser
  });

  const enableEmailOtp = () => {
    updateUserOtpType({ variables: { id: currentUser.id, otpType: 'email' } });
  };

  useEffect(() => {
    const otpRequiredForLogin = currentTry > 1;

    if (6 === code.length && 'app' !== currentUser.otpType) {
      setFormStatus('checking');
      verifyTotp({ variables: { code, otpRequiredForLogin } });
    }
  }, [code]);

  let content = (
    <Button label="Generate QR Code" onPress={setupTOTP} wide />
  );

  if ('app' === currentUser.otpType) {
    content = (
      <Result>
        {'disable-failed' === formStatus && (
          <Notice type="warning">
            Code did not match. Please try again.
          </Notice>
        )}
        <Notice type="success">
          You have two-factor authentication enabled.
        </Notice>
        <Text>
          To disable, enter the current code from your two-factor authenticator
          below, then click 'Disable'.
        </Text>
        <StyledTextInput value={code} onChange={e => setCode(e.target.value)} />
        <Button
          label="Disable"
          onPress={() => disableTotp({ variables: { code } })}
          wide
        />
      </Result>
    );
  } else if (0 == currentTry && svg) {
    content = (
      <Result>
        <Text>
          Scan the below QR Code with your two-factor authenticator.
          Once you've added OklahomaTempTag.com to your authenticator
          app, you'll be asked to enter to 6-digit codes to verify your
          setup.
        </Text>
        <img
          src={`data:image/svg+xml;base64,${svg}`}
          style={{ maxWidth: 300 }}
        />
        <Button label="Continue" onPress={() => setCurrentTry(1)} wide />
      </Result>
    );
  } else if ('success' === formStatus && currentTry > 2) {
    content = (
      <Result>
        <Notice type="success">
          Your two-factor authentication is validated.
          Your logins will now require two-factor authentication.
        </Notice>
      </Result>
    );
  } else if (currentTry > 0) {
    content = (
      <Result>
        {'invalid' === formStatus && (
          <Notice type="warning">
            Code did not match. Please try again.
          </Notice>
        )}
        {'disable-success' === formStatus && (
          <Notice type="success">
            You have disable two-factor authentication. You can re-enable below.
          </Notice>
        )}
        {currentTry > 1 ? (
          <Text>
            Wait for a new code on your two-factor authenticator then enter
            {' '}the code ({currentTry} of 2).
          </Text>
        ) : (
          <Text>
            Enter the code from your two-factor authenticator
            {' '}({currentTry} of 2)
          </Text>
        )}
        <StyledTextInput
          autoFocus
          value={code}
          onChange={e => setCode(e.target.value)}
        />
      </Result>
    );
  }

  return (
    <View>
      {'none' === currentUser.otpType && (
        <View style={{ marginBottom: 40 }}>
          <Title>Re-enable Two-Factor Auth Using Email Code</Title>
          <Text>
            Two-factor email code is currently disabled for your account.
            Click below to re-enable.
          </Text>
          <Button label="Enable Emailed Code" onPress={enableEmailOtp} wide />
        </View>
      )}
      <Title>Setup Two-Factor Auth Using an Authenticator App</Title>
      {content}
    </View>
  );
};

export default SetupTOTP;
